import React, { useState } from "react";
import CustomModal from "../../Parts/Company/CustomModal";
import "./style.css";

export default function PhonePlan() {
  const [showModal, setShowModal] = useState(false);
  const topics = [
    {
      value: "299",
      title: "EASY",
      color: "gray-plan",
      main_topics: ["600 MEGA Internet", "01 linha tronco", "05 canais SIP"],
      sub_topics: [
        "Cloud IPBx",
        "Atendimento Automático",
        "Gravação Chamadas 30D",
        "VoiceBots",
        "URA",
        "Pesquisa de Satisfação",
        "05 Ramais IP",
        "Fixo Local Ilimitado",
      ],
    },
    {
      value: "699",
      title: "PROFESSIONAL",
      color: "blue-plan",
      main_topics: ["600 MEGA Internet", "01 linha tronco", "10 canais SIP"],
      sub_topics: [
        "Cloud IPBx",
        "Atendimento Automático",
        "Gravação Chamadas 60D",
        "VoiceBots",
        "URA",
        "Pesquisa de Satisfação",
        "10 Ramais IP",
        "Fixo Local Ilimitado",
      ],
    },
    {
      value: "999",
      title: "ENTERPRISE",
      color: "red-plan",
      main_topics: [
        "1000 MEGA Internet",
        "01 linha tronco",
        "E1 SIP 30 Canais",
      ],
      sub_topics: [
        "Stand Alone IPBx",
        "Atendimento Automático",
        "Gravação Chamadas Ilimitado",
        "VoiceBots",
        "URA",
        "API Integrações",
        "Pesquisa de Satisfação",
        "Ramais Ilimitados",
        "Fixo Local Ilimitado",
      ],
    },
  ];

  const details = [
    {
      main_title: "Plano de Tarifação Telefônica",
      main_topics: ["Fixo Local Ilimitado", "Fixo DDD", "Móvel DDD", "DDI"],
      sub_title: "Recursos PABX IP",
      sub_topicss: [
        "Contexto de saída",
        "Ramais - R$0,12",
        "Grupo de ramais - R$0,42",
        "URA - R$1,77",
        "Callback",
        "Relatório de Rastreabilidade",
        "Relatório de Rastreabilidade Sintético",
        "Relatório Sintético URA",
        "Relatório Analitico URA",
        "Relatório de Volumetria",
        "Monitoramento",
        "Agente",
        "Comandos",
        "Relatório de Login",
        "Relatório de Produtividade",
        "Relatório de Pausas",
        "Estatística ACD",
        "Rastreabilidade de Agentes",
        "Relatório de Volumetria de Agentes",
        "Relatório de Chamadas Analitico",
        "Relatório de Chamadas Não Atendidas",
        "Filas",
        "Monitoramento de Filas",
        "Estatístico ACD",
        "Estatísticas de Chamadas",
        "Relatório Analítico de Chamadas Recebidas",
        "Relatório de Nível de Serviço",
        "Grupo de Telefones",
        "Agendamento de Ligações",
        "Classificação de Atendimento",
        "Relatório Analitico",
        "Grupo de Música",
        "Anúncios",
        "Pesquisa de Satisfação",
        "Relatório",
        "Operadora - Tronco SIP",
        "Comandos Gerais",
        "Siga-me",
        "Discagem Rápida",
        "Lista Negra",
        "Monitoramento de Ligações Online",
        "Operadoras SIP",
      ],
    },
  ];
  return (
    <section id="phone-plan">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title text-center">
              <span className="c-primary">Internet </span> +{" "}
              <span className="c-primary"> Telefonia </span> +{" "}
              <span className="c-primary"> IPBX </span>
            </h2>
          </div>
        </div>
        <div className="row mt-5">
          {topics.map((topic, key) => (
            <div className="col-md-4" key={key}>
              <div className="card bg-light shadow-lg rounded-card ">
                <div className="card-title">
                  <div className="d-flex justify-content-between align-items-center">
                    <div
                      className={`plan-header-speed text-white fw-bold d-flex align-items-center fs-5 ${topic.color}`}
                    >
                      {topic.title}
                    </div>
                    <div className="plan-header-value fs-4 fw-bold d-flex p-3">
                      <h2 className="fw-bold">{topic.value}</h2>/ mês
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <ul>
                    {topic.main_topics.map((topic, index) => (
                      <li key={index} className="main-plan-list">
                        {topic}
                      </li>
                    ))}
                    {topic.sub_topics.map((topic, index) => (
                      <li key={index} className="sub-plan-list">
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="card-footer bg-white">
                  <div className="d-grid gap-3">
                    <div
                      className="row cursor-pointer"
                      onClick={() => setShowModal(!showModal)}
                    >
                      <div className="col-md-12 text-center mt-2">
                        <span className="fs-18 c-gray font-italic fw-600">
                          + detalhes dessa oferta
                        </span>
                      </div>
                    </div>
                    <a
                      href="https://api.whatsapp.com/send?phone=5508000800999"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary text-white p-3"
                      onClick={() =>
                        window.dataLayer.push({ event: "Checar Disponibilidade" })
                      }
                    >
                      Checar disponibilidade
                    </a>
                  </div>
                </div>
                {showModal ? (
                  <CustomModal
                    scrollable={true}
                    title="+ Detalhes"
                    setShowModal={setShowModal}
                  >
                    <ul>
                      {details.map((detail, index) => (
                        <>
                          <li key={index} className="main-plan-list">
                            {detail.main_title}
                          </li>
                          {detail.main_topics.map((topic, index) => (
                            <li className="sub-plan-list" key={index}>
                              {topic}
                            </li>
                          ))}
                          <hr color="#000000" />
                          <li className="main-plan-list">{detail.sub_title}</li>
                          {detail.sub_topicss.map((topic, index) => (
                            <li className="sub-plan-list" key={index}>
                              {topic}
                            </li>
                          ))}
                        </>
                      ))}
                    </ul>
                  </CustomModal>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
