import React from 'react';
import BenefitItems from '../../../Components/BenefitItems';

import Benefit1 from '../../../assets/img/Phone/PESSOA-TEL.webp';
import Benefit2 from '../../../assets/img/Phone/MAO-CELULAR.webp';
import Benefit3 from '../../../assets/img/Phone/MENINA-CELULAR-02.webp';
import Benefit4 from '../../../assets/img/Phone/MENINA-CELULAR-03.webp';

function Benefits() {
    const benefits = [
        {
            contents: [
                {
                    title: "Adquira paz de espírito.",
                    description: "Não há sinal de celular para se preocupar e você tem um telefone residencial pronto para usar."
                },
                {
                    title: "Receba suas ligações, onde você estiver.",
                    description: "Encaminhe chamadas para seu telefone celular ou outro dispositivo."
                }
            ],
            img: Benefit1,
            alt: "Benefícios"
        },
        {
            contents: [
                {
                    title: "Todos em casa podem usar.",
                    description: "O Telefone LOViZ é simples de usar para todos, desde crianças até aqueles que preferem um telefone padrão, mais tradicional."
                },
                {
                    title: "Decida quem pode te ligar e quando.",
                    description: "Especifique de quem você aceitará chamadas através de controles de privacidade."
                }
            ],
            img: Benefit2,
            alt: "Benefício"
        },
        {
            contents: [
                {
                    title: "Filtre ruidos.",
                    description: "Use o recurso de Bloqueio Automático de Spam, silencie seu telefone ou ative o Modo Não Perturbe."
                },
                {
                    title: "Fique em contato.",
                    description: "Desfrute de chamadas locais gratuitas, além de tarifas acessíveis de chamadas internacionais."
                }
            ],
            img: Benefit3,
            alt: "Benefício"
        },
        {
            contents: [
                {
                    title: "Acompanhe seu Correio de Voz.",
                    description: "Faça com que seus correios de voz sejam transcritos e enviados para seu e-mail ou caixa de mensagem SMS."
                },
                {
                    title: "Salve suas chamadas.",
                    description: "Grave suas ligações e consulte-as mais tarde."
                }
            ],
            img: Benefit4,
            alt: "Benefício"
        }
    ]
    return (
        <section id="benefits">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-title text-center">
                            Veja o que você pode fazer<br />com o telefone LOViZ
                        </h2>
                    </div>
                </div>
                <div className="benefits-items row">
                    <div className="col-md-12">
                        <BenefitItems sections={benefits} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Benefits;