import React, { useEffect } from "react";
import "./style.css";
import video from "../../../assets/img/GigabitInternet/car.mp4";

export default function IntroductionSecond() {
  return (
    <div id="introduction-second">
      <div className="container">
        <div className="row justify-content-around">
          {/* Div created for item-1 */}
          <div className="item-text col-md-6">
            <div className="row mobile-mt-20">
              <div className="col-md-12">
                <h1 className="c-dark fs-40 mobile-fs-35">
                  Faça tudo mais <br /> rápido -
                  <span className="fs-40 c-primary mobile-fs-35">
                    {" "}
                    em todas as direções
                  </span>
                </h1>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h2
                  className="fs-20 ptb-20 c-dark font-italic d-flex fw-600"
                  id="fast-before"
                >
                  Bem mais rápido
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="quotes">
                  <h2
                    className="fs-20 c-dark font-italic fw-600 d-flex"
                    id="before-item-quote-1"
                  >
                    Nós garantimos, rápido <br /> mesmo.
                  </h2>
                  <p
                    className="fs-12 c-dark font-italic fw-600 d-flex mt-10 wt-270"
                    id="before-item-quote-2"
                  >
                    Você também obterá velocidades de upload até 77x mais
                    rápidas do que a Internet a cabo e velocidades de download
                    6x mais rápidas.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Div created for item-2 */}
          <div className="col-md-6">
            <div className="row">
              <div className={"col-md-12 mobile-padding"}>
                <video
                  src={video}
                  alt="Car running"
                  autoPlay={true}
                  type="video/mp4"
                  className="border-radius-12 car-running"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
