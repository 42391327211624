import React from 'react';

import './style.css'
import FirstSectionImg from '../../../assets/img/Phone/first-section.webp'

import FormCep from '../../../Components/FormCep';

function FirstSection() {
    return (
        <section id="first-phone-section" className="pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-5 d-flex align-items-end mt-50">
                        <FormCep sectionClass="p-0" parentColumnSize="12"
                            componentText="A telefone LOViZ te oferece o melhor de um telefone residencial - com recursos de Internet inteligente."
                            responsiveFieldsColumn="col-12 col-sm-12 col-md-12 col-lg-12"
                            responsiveButtonColumn="col-12 col-sm-12 col-md-12 col-lg-12 mt-20" />
                    </div>
                    <div className="d-none d-md-block col-md-4 col-lg-4 col-xl-6 offset-md-1 offset-lg-1 offset-xl-1 first-section-background" style={{ background: `url(${FirstSectionImg})` }} />
                </div>
            </div>
        </section>
    );
}

export default FirstSection;