import React, { useState } from 'react';

import './style.css'

function Tab({ tabs }) {
    const [activeTab, setActiveTab] = useState(0);

    function handleCollapsedQuestion(key) {
        const question = document.querySelector(`#question-${key}`);
        if (question.classList.contains('active-question')) {
            question.classList.remove('active-question');
        } else {
            question.classList.add('active-question');
        }
    }

    function handleTab(key) {
        Array.from(document.getElementsByClassName('active-question')).forEach(el => el.classList.remove('active-question'))
        setActiveTab(key)
    }

    return (
        <section id="tab">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="d-flex">
                            {
                                tabs.map((tab, key) => (
                                    <li className={`text-center fs-20 ${key == activeTab ? 'active' : ''}`} onClick={(e) => handleTab(key)}>
                                        <a dangerouslySetInnerHTML={{ __html: tab.title}}></a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 tab-content">
                        {
                            tabs[activeTab]['content']
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tab;