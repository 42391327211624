import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import Logo from "../../assets/img/LOViZ_LOGO_PRINCIPAL_RGB.png";
import "./style.css";
import { Dropdown } from "react-bootstrap";

export default function LPHeader({ showMobileMenu, setShowMobileMenu }) {
  return (
    <header className="header">
      {showMobileMenu ? <div className="overlay mobile-overlay"></div> : null}
      <div className="container">
        <div className="row align-items-center">
          <div className="left-column col-8 col-sm-8 col-md-4 col-lg-4 d-flex align-items-lg-end">
            <Link to="/">
              <img alt="logo" src={Logo} width="200px" />
            </Link>
            <span className="ml-20">por um mundo melhor!</span>
          </div>
          <div className="col-4 col-sm-4 col-md-8 col-lg-8">
            <nav
              className={`menu-container d-md-flex justify-content-between justify-content-md-center justify-content-md-end header-menu ${
                showMobileMenu ? "active-mobile-menu" : ""
              }`}
            >
              <div>
                <div className="d-md-none menu-mobile-top row align-items-center">
                  <div className="col-4">
                    <FontAwesomeIcon
                      onClick={() => setShowMobileMenu(!showMobileMenu)}
                      cursor="pointer"
                      fontSize="25px"
                      icon={faTimes}
                      className="c-primary fs-25"
                    />
                  </div>
                  <div className="col-8">
                    <Link to="/">
                      <img alt="logo" src={Logo} width="200px" />
                    </Link>
                  </div>
                </div>
                <ul className="header-menu-items">
                  <li>
                    <a href="#internet">Internet</a>
                  </li>
                  <li>
                    <a href="#loviz">LOViZ</a>
                  </li>
                  <li>
                    <a href="#company-plans">Planos</a>
                  </li>
                  <li>
                    <a href="#contato">Contato</a>
                  </li>
                  <li>
                    <a href="#faq">FAQ</a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="d-flex justify-content-end d-md-none">
              <FontAwesomeIcon
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                cursor="pointer"
                fontSize="25px"
                icon={faBars}
                className="c-primary fs-25"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
