import React from 'react';

import './style.css'

function SuperWifi() {
    return (
        <section id="first-section">
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-md-8 col-lg-8 col-xl-5 text-left">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="fw-600 fs-60">Por que sua internet rápida e confiável deve parar naquela parede?</h2>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <p className="font-italic fw-600">Você não deveria ter que escolher entre internet rápida e usar dispositivos sem fio em qualquer lugar de sua casa. É por isso que, com a Internet da LOViZ, você terá um Wi-Fi capaz de lidar com tudo o que estiver fazendo sem fio.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SuperWifi;