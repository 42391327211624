import React, {useState} from "react";

import BannerImage from "../../assets/img/banner-home.jpg";
import Banner from "../../Components/Banner";
import FormCPF from "../../Components/FormCPF";
import ViaApi from "../../Parts/Via";

import './style.css';

function Via(){
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window;
        return {
            width,
            height,
        };
    }
    return(
        <div id="via">
            <Banner
                backgroundOverlay={
                    windowDimensions["width"] < 768
                        ? "rgb(255, 255, 255, .7), rgb(255, 255, 255, .7)"
                        : "rgb(255, 255, 255) 30%, transparent"
                }
                background={BannerImage}
                principalTitle=""
                backgroundPosition="center"
                secondaryTitle="Faça o download da 2º via de sua conta LOViZ"
                description=''
            />
            <FormCPF
                sectionClass="bg-gray p-0"
                parentColumnSize="9"
                containsWhatsAppColumn={true}
            />
            {/*<ViaApi />*/}
        </div>
    )
}

export default Via;