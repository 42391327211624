import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function CustomCarousel({ items, sectionTitle }) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const CustomLeftArrow = ({ onClick }) => (
    <button
      onClick={() => onClick()}
      className="carousel-arrow carousel-left-arrow"
      type="button"
    >
      <FontAwesomeIcon className="c-primary" icon={faArrowLeft} />
    </button>
  );

  const CustomRightArrow = ({ onClick }) => (
    <button
      onClick={() => onClick()}
      className="carousel-arrow carousel-right-arrow"
      type="button"
    >
      <FontAwesomeIcon className="c-primary" icon={faArrowRight} />
    </button>
  );

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section id="custom-carousel">
      <div className="container-fluid">
        <div className="row mb-20">
          <div className="col-md-12 text-center">
            <h2
              className="section-title"
              dangerouslySetInnerHTML={{ __html: sectionTitle }}
            ></h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={windowDimensions.width < 1200 ? false : true}
              draggable
              focusOnSelect={false}
              infinite={windowDimensions.width < 1200 ? false : true}
              keyBoardControl
              renderButtonGroupOutside={false}
              responsive={responsive}
              slidesToSlide={1}
              swipeable
              showDots={true}
              customLeftArrow={<CustomLeftArrow />}
              customRightArrow={<CustomRightArrow />}
            >
              {items.map((item, key) => (
                <div
                  key={key}
                  className="ptb-20 carousel-figure d-flex flex-column align-items-center"
                >
                  {item.video !== undefined ? (
                    <video
                      // width="550px"
                      // height="300px"
                      preload="none"
                      autoPlay
                      muted
                      loop
                      id="carousel-video"
                      playsInline
                    >
                      <source src={item.video} />
                    </video>
                  ) : (
                    <img src={item.image} width="550px" height="300px"  alt="carousel"/>
                  )}
                  <div className="mt-30 d-flex flex-column align-items-center carousel-description">
                    {item.image_logo ? <img alt="carousel" src={item.image_logo} id="logo-image"/> : null}
                    <span className="carousel-figure-title fs-18">
                      {item.title}
                    </span>
                    <span className="fs-15 c-gray mt-10 text-center">
                      {item.description}
                    </span>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
}
