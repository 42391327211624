import React, { useState } from 'react';
import Form from '../Form';
import './style.css';

export default function ButtonCall({ title, color = "#ed1651" }) {
   const [modal, setModal] = useState(false);

   function openModal(){
      setModal(!modal);
      return;
   }

   function closeModal(){
      setModal(false);
      return
   }


   return (
      <>
      <button style={{ borderColor: color, color: color }} className="button-call" onClick={openModal}>
         {title}
      </button>
      {modal ? <Form modal={modal} setModal={setModal}/> : null}
      </>
   );
}