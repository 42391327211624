import React from "react";
import IMGBanner from "../../assets/LP/banner-residencias.webp";

export default function Banner() {
  return (
    <section className="bg-gray">
      <div className="lp-container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <img src={IMGBanner} alt="" className="img-fluid rounded" />
          </div>
          <div className="col-md-6 col-sm-12 d-flex flex-column align-items-start justify-content-center">
            <h2 className="c-dark">Chega de len...ti...dão...</h2>
            <h2 className="c-dark">Conheça a <span className="c-primary">melhor internet</span> do mundo</h2>
            <ul className="banner-list">
                <li  className="c-gray font-itallic fs-18 fw-600">Mais cobertura</li>
                <li className="c-gray font-itallic fs-18 fw-600">Mais conexão</li>
                <li className="c-gray font-itallic fs-18 fw-600">Mais velocidade</li>
            </ul>
            <a href="#company-plans" className="btn btn-primary btn-lg text-white mobile-center">Assinar</a>
          </div>
        </div>
      </div>
    </section>
  );
}
