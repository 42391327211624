import React, { useEffect } from "react";
import "./style.css";
import Introduction from "../../Parts/GigabitInternet/Introduction";
import FormCep from "../../Components/FormCep";
import IntroductionSecond from "../../Parts/GigabitInternet/IntroductionSecond";
import TypeInternet from "../../Parts/GigabitInternet/TypeInternet";
import Announcement from "../../Parts/GigabitInternet/Announcement";
import SecondSection from "../../Parts/GigabitInternet/SecondSection";
import ContactUs from "../../Components/ContactUs";

export default function Gigabit() {
  useEffect(() => {
    const animeItens = document.querySelectorAll("[data-anime]");
    animeItens.forEach((el, index) => {
      setTimeout(() => el.classList.add("active"), index * 100);
    });
  }, []);



  return (
    <section id="gigabit-internet">
      <Introduction />
      <FormCep hideText={true} sectionClass="p-0" />
      <IntroductionSecond />
      <TypeInternet />
      <Announcement />
      <SecondSection />
      <ContactUs link={'https://api.whatsapp.com/send?phone=558000800999&text=Internet%20Gigabit'} />
    </section>
  );
}
