import React, { useState, useEffect } from 'react';

import './style.css'

function VerticalTab({ title = "", tabList }) {
    const [activeTab, setActiveTab] = useState(0);

    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="vertical-tab">
            <div className="row">
                <div className="col-md-12 col-lg-12 col-xl-4">
                    {
                        windowDimensions.width >= 1140
                            ?
                            <div className="tab-items">
                                {
                                    tabList.map((tab, key) => (
                                        <div key={key} className={`row tab-item bg-light-gray ${key === activeTab ? 'active' : ''}`} onClick={() => setActiveTab(key)}>
                                            <div className="col-md-12">
                                                <span className="ml-20 c-gray font-italic fs-15">{tab.title}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <select name="tabs" id="tab-list" onChange={(e) => setActiveTab(e.target.value)}>
                                {
                                    tabList.map((tab, key) => <option value={key}>{tab.title}</option>)
                                }
                            </select>
                    }
                </div>
                <div className="col-md-12 col-lg-12 col-xl-8 tab-content-container">
                    {
                        title
                            ?
                            <div className="row tab-content tab-title">
                                <div className="col-md-12">
                                    <span className="c-gray fw-600 fs-15">{title}</span>
                                </div>
                            </div>
                            :
                            null
                    }
                    <div className="row">
                        <div className="col-md-12">
                            {
                                tabList[activeTab].content
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerticalTab;