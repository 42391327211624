import React from "react";
import { Link } from "react-router-dom";

export default function SecondSectionItems({
  title,
  content,
  image,
  isInverted /* For default return the image first and after the content, when you invert the order the content comes first and image after.*/,
  containLink /* The value must be Boolean, so if you set true it'll show the link */,
  linkText = "Saiba mais",
  index,
  linkTo = "super-wifi",
}) {
  return (
    <div
      className={`row mt-80 mobile-mt-40 justify-content-around ${
        isInverted ? "flex-row-reverse" : null
      }`}
      key={index}
    >
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12">
            <h1 className="c-dark fs-20 fw-600 ptb-20 font-italic mobile-ptb-10 ">
              {title}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p
              className="c-dark fs-14 wt-460 c-gray font-italic"
              id="width-text"
            >
              {content}
            </p>
          </div>
        </div>

        {containLink ? (
          <div className="row mobile-ptb-10">
            <div className="col-md-12">
              {
                linkTo.indexOf('/') === 0
                ?
                <Link to={linkTo} className="c-primary fs-12 mobile-fs-14">
                {linkText}
              </Link>
                :
                <a href={linkTo} target='_blank' rel='noreferrer' className="c-primary fs-12 mobile-fs-14">
                  {linkText}
                </a>
              }
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <img
              src={image}
              alt={image}
              className="border-radius-12 mobile-img mobile-img-item"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
