import React from "react";
import "./style.css";

function Banner() {
    return (
        <section id="banner-work">
            <div className="container">
                <div className="row mobile-margin-5">
                    <div className="border-radius-12 d-flex justify-content-center">
                        <div className="mobile-show">
                            <iframe src="https://player.vimeo.com/video/640412069?h=ebf7a1e891&amp;badge=0&amp;autoplay=1&loop=1&autopause=0&background=1"
                                    width="375" height="200"
                                    frameBorder="0" allow="autoplay;"
                                    allowFullScreen></iframe>
                        </div>
                        <div className="mobile-hide">
                            <iframe src="https://player.vimeo.com/video/640412069?h=ebf7a1e891&amp;badge=0&amp;autoplay=1&loop=1&autopause=0&background=1"
                                    width="1280" height="500"
                                    frameBorder="0" allow="autoplay;"
                                    allowFullScreen></iframe>
                            {/*<iframe src="https://player.vimeo.com/video/643604630?h=f117f4559a?autoplay=1" width="640" height="360"*/}
                            {/*        frameBorder="0" allow="autoplay;"*/}
                            {/*        allowFullScreen></iframe>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner;

