import React from "react";
import Video from "../../assets/img/GigabitInternet/house_video.mp4";
import IMG from "../../assets/LP/Casa.gif";

export default function Internet() {
  return (
    <section id="internet">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12 d-flex flex-column align-items-end justify-content-center">
            <h2 className="fs-25 c-dark font-italic fw-600 mobile-fs-20">
              Suas páginas demoram a carregar
              <span className="c-primary">?</span>
            </h2>
            <h2 className="fs-25 c-dark font-italic text-end fw-600 mobile-fs-20">
              Nunca consegue assistir um filme por completo
              <span className="c-primary">?</span>
            </h2>
            <h2 className="fs-25 c-dark font-italic fw-600 mobile-fs-20">
              Videochamadas costumam travar<span className="c-primary">?</span>
            </h2>
            <p className="c-gray font-italic fs-20 text-right wt-435 mt-10">
              Você não está sozinho(a)!<br/>
              Mas pode dar adeus a esse pesadelo.
              Cobertura para toda sua casa! <br/>
              Bem-vindo(a) ao universo{" "}
              <span className="c-primary">LOVıZ!</span>
            </p>
            <a
              href="#vantagens"
              className="btn btn-primary btn-lg text-white mobile-center"
            >
              Quero saber mais
            </a>
          </div>
          <div className="col-md-6 col-sm-12">
            <img src={IMG} alt="Casa" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}
