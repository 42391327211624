import React from "react";
import LovizIMG from "../../assets/LP/loviz-1.webp";
export default function Loviz() {
  return (
    <section id="loviz" >
      <div className="container strip rounded">
        <div className="row">
        <div className="col-md-7 col-sm-12">
            <img src={LovizIMG} alt="" className="img-fluid rounded" />
          </div>
          <div className="col-md-5 col-sm-12 d-flex flex-column justify-content-center align-items-start">
            <h2 className="fs-25 c-white font-italic fw-600 mobile-fs-20">
              Por um mundo melhor...
            </h2>
            <p className="c-white font-italic fs-20 text-justify wt-435 mt-10">
              ...por mais amor, mais união, mais conexão! Enxergamos o mundo
              além do gigabyte, das velocidades e dos uploads. Queremos ser
              parte da sua história, parceiro do seu negócio, incentivador da
              sua carreira... Transformar o mundo ao seu lado, compartilhando
              nosso coração rosa!
            </p>
          </div>
          
        </div>
      </div>
    </section>
  );
}
