import React from "react";

import Image from "../../assets/img/404.jpg"

const PageNotFound = () => {
    return (
        <section id="terms" className="pt-0">
            <div className="container contact-banner ptb-50">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="row mt-20 justify-content-center align-items-center">
                            <div className="col">
                                <img src={Image} alt=""/>
                            </div>
                            <div className="col-md-8 text-justify d-flex flex-column gap-3">
                                <span className="fs-30 fw-600 text-danger">
                                   Erro 404 - Nenhum resultado encontrado
                                </span>
                                <span className="fs-22 fw-600">
                                    A página que você solicitou não foi encontrada. Tente usar a navegação acima ou o rodapé abaixo para localizar a postagem.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageNotFound;