import React from "react";

import ShuffleImg from "../../../assets/img/Company/shuffle.webp";
import WithoutDataLimitImg from "../../../assets/img/Company/sem-limite-de-dados.webp";
import HeadsetImg from "../../../assets/img/Company/headset.webp";

function Included() {
  return (
    <section id="included-in-all-plans" className="bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title text-center">
              Incluído em todos os{" "}
              <span className="c-primary">nossos planos:</span>
            </h2>
          </div>
        </div>
        <div className="row mt-50 justify-content-center">
          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12 d-flex align-items-center justify-content-center">
                <img src={ShuffleImg} alt="Planos Flexíveis" width="80px" />
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12 text-center">
                <h3 className="fs-20 mb-0">Planos flexíveis.</h3>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12 text-center">
                <span className="c-gray fs-14">
                  Faça upgrades ou cancele a qualquer momento. Você pode até
                  usar seu próprio roteador, se desejar.
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3 mobile-mt-20">
            <div className="row">
              <div className="col-md-12 d-flex align-items-center justify-content-center">
                <img
                  src={WithoutDataLimitImg}
                  alt="Sem limite de dados"
                  width="80px"
                />
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12 text-center">
                <h3 className="fs-20 mb-0">Sem limite de velocidade.</h3>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12 text-center">
                <span className="c-gray fs-14">Navegue o quanto desejar.</span>
              </div>
            </div>
          </div>
          <div className="col-md-3 mobile-mt-20">
            <div className="row">
              <div className="col-md-12 d-flex align-items-center justify-content-center">
                <img
                  src={HeadsetImg}
                  alt="Suporte ao cliente 24h"
                  width="80px"
                />
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12 text-center">
                <h3 className="fs-20 mb-0">Suporte ao cliente 24h</h3>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-12 text-center">
                <span className="c-gray fs-14">
                  Obtenha ajuda 24h de nossa equipe de suporte pelo WhatsApp ou
                  por telefone.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Included;
