import React from "react";

import Banner from "../../Components/Banner";
import Items from "../../Parts/Business/Items";
import Icons from "../../Parts/Business/Icons";
import BG from "../../assets/img/Business/banner.webp"

function Business(){
    return (
        <div id="business">
            <Banner background={BG}
                    backgroundOverlay="transparent, rgb(0, 0, 0, .5) 60%"
                    classPrincipalTitleColor="c-white text-right"
                    principalTitle="Inovadora, disruptiva..</br>...e com propósito claro de conectar pessoas"
                    classFontColor="c-white text-right"
                    backgroundPosition="center"
            />
            <Items />
            <Icons />
        </div>
    )
}

export default Business;