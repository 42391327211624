import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import "./style.css";
import { useEffect } from "react";

function FAQ({ questions }) {
  function handleCollapsedQuestion(key) {
    const question = document.querySelector(`#question-${key}`);
    if (question.classList.contains("active-question")) {
      question.classList.remove("active-question");
    } else {
      question.classList.add("active-question");
    }
  }

  useEffect(() => {
    Array.from(document.getElementsByClassName("active-question")).forEach(
      (el) => el.classList.remove("active-question")
    );
  }, []);

  return questions.map((question, key) => (
    <div id={`question-${key}`} className="row faq-item">
      <div
        className="faq-item-top"
        onClick={(e) => handleCollapsedQuestion(key)}
      >
        <div className="row">
          <div className="col-8 col-sm-8 col-md-8 col-lg-8">
            <span className="fs-18 c-dark">{question.title}</span>
          </div>
          <div className="col-4 col-sm-4 col-md-4 col-lg-4 text-right">
            <FontAwesomeIcon
              className="c-primary"
              size="lg"
              width="25px"
              height="25px"
              icon={faChevronDown}
            />
          </div>
        </div>
      </div>
      <div className="faq-item-content mt-40">
        <div className="row">
          <div className="col-md-12">
            <p dangerouslySetInnerHTML={{ __html: question.content}}></p>
          </div>
        </div>
      </div>
    </div>
  ));
}

export default FAQ;
