import React from "react";

import SuperWifi from "../../../assets/img/4.5G/super-wifi.webp";
import TopViewWiFi from "../../../assets/img/top-view-wi-fi-router.webp";
import WifiToAll from "../../../assets/img/wifi-to-all.webp";

import Video1 from '../../../assets/img/Condominium/item-1.mp4';
import Video2 from '../../../assets/img/Condominium/item-2.mp4';
import Video3 from '../../../assets/img/Condominium/item-3.mp4';

// import Item1 from '../../assets/img/Condominium/item-1.mp4';
// import Item2 from '../../assets/img/Condominium/item-2.mp4';
// import Item3 from '../../assets/img/Condominium/item-3.mp4';

function Videos(){
    return(
        <section id="superwifi-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="section-title fs-35">
                            Velocidade que <span className="c-primary ws-pre">seu condomínio precisa.</span>
                        </h2>
                    </div>
                </div>

                <div id="wifi-to-all" className="row mt-80 justify-content-center">
                    <div className="col-md-4 d-flex justify-content-center mobile-mt-40">
                        <video
                            src={Video1}
                            alt="House"
                            autoPlay={true}
                            loop={true}
                            type="video/mp4"
                            className="border-radius-12 car-running"
                        />
                    </div>
                    <div className="col-md-4 d-flex flex-column justify-content-center">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="fs-35 fw-600">
                                    Mais velocidade
                                </h3>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <p className="c-gray fs-15 mb-0">
                                    Garantia de alta velocidade a um custo que cabe no seu bolso.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div id="router-section" className="row mt-50 justify-content-center">
                    <div className="col-md-4 d-flex flex-column justify-content-center">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="fs-35 fw-600 text-right">
                                    Planos personalizados
                                </h3>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <p className="c-gray fs-15 mb-0 text-right">
                                    Soluções especiais para condomínios residenciais e empresariais.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex mobile-mt-40">
                        <video
                            src={Video2}
                            alt="House"
                            autoPlay={true}
                            loop={true}
                            type="video/mp4"
                            className="border-radius-12 car-running"
                        />
                    </div>
                </div>
                <div id="wifi-to-all" className="row mt-80 justify-content-center">
                    <div className="col-md-4 d-flex justify-content-center mobile-mt-40">
                        <video
                            src={Video3}
                            alt="House"
                            autoPlay={true}
                            loop={true}
                            type="video/mp4"
                            className="border-radius-12 car-running"
                        />
                    </div>
                    <div className="col-md-4 d-flex flex-column justify-content-center">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="fs-35 fw-600">
                                    Condomínios inteligentes
                                </h3>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <p className="c-gray fs-15 mb-0">
                                    Planos atraentes para todos os moradores e condôminos.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Videos;