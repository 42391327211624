import React, { useEffect, useRef } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import img1 from "../../../assets/img/Ecossistema/01-Internet-gratis-para-escolas-publicas.webp";
import img2 from "../../../assets/img/Ecossistema/02-Campanha-de-apoio-emergencial.webp";
import img3 from "../../../assets/img/Ecossistema/03-Fatura-digital.webp";
import img4 from "../../../assets/img/Ecossistema/04-Use-LOViZ.webp";

function Items({
  title,
  content,
  image,
  index,
  textLink = "Saiba mais",
  path = "/home",
}) {
  return (
    <div className={`row pb-40 mt-20 items justify-content-center`} key={index}>
      {/* First Item - Img*/}
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <img
              src={image}
              alt="img"
              className="announcement-img-ecossistema border-radius-12"
            />
          </div>
        </div>
      </div>
      {/* Second Item - Content*/}
      <div className="col-md-4 mt-20">
        {/* Title's Row */}
        <div className="row">
          <div className="col-md-12">
            <h2 className="fs-25 c-dark fw-600 font-italic ml-10">{title}</h2>
          </div>
        </div>
        {/* Subtitle's Row */}
        <div className="row mt-10">
          <div className="col-md-12">
            <p className="fs-14 fw-600 c-gray font-italic ml-10">{content}</p>
          </div>
        </div>
        {/* Link's Rows */}
        <div className="row mt-20">
          <div className="col-md-12">
            {path === "educacao" ? (
              <Link to={path} className="fs-15 fw-600 c-primary ml-10">
                {textLink ? textLink : "Saiba Mais"}
              </Link>
            ) : (
              <a href={path} rel='noreferrer' target='_blank' className="fs-15 fw-600 c-primary ml-10">
                {textLink ? textLink : "Saiba Mais"}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Announcement() {
  const itens = [
    {
      title: `Internet grátis para escolas públicas`,
      content: `Educação para todos é um direito. Educação com
      internet rápida e gratuita para escolas públicas é
      nossa missão.`,
      image: img1,
      path: "educacao",
    },

    {
      title: `Campanha de apoio emergencial`,
      content: `Comida e itens de higiene nas mãos de quem
      precisa. Em 2021, resolvemos ajudar famílias e
      pessoas em situação de vulnerabilidade social.`,
      image: img2,
      path: "https://youtu.be/Vpjhchx0rOQ",
    },

    {
      title: `Fatura digital`,
      content: `Menos papel. Menos desperdício. Mais consciência.
      A fatura digital representa a preservação de pelo
      menos 700 árvores por ano.`,
      textLink: "Solicite todo mês a sua",
      image: img3,
      path: "https://api.whatsapp.com/send?phone=5508000800999&text=FATURADIGITAL]",
    },

    // {
    //   title: `Use LOViZ`,
    //   content: `Estilo que alimenta! Como parceira da Reserva, a
    //   cada peça comprada, 5 pratos de comida são
    //   doados no Brasil.`,
    //   image: img4,
    //   path: "https://www.reserva.ink/useloviz",
    // },
  ];
  return (
    <section>
      <div className="container">
        <div className="row mt-20 mb-20">
          <div className="col-md-12 pb-40 mobile-pb-16">
            <h1 className="section-title text-center mobile-text-center mobile-fs-25">
              Trabalho com conciência <br /> social e socioambiental
            </h1>
          </div>
        </div>
        {itens.map(({ title, content, image, textLink, path }, index) => (
          <Items
            title={title}
            content={content}
            image={image}
            key={index}
            textLink={textLink}
            path={path}
          />
        ))}
      </div>
    </section>
  );
}
