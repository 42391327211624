import React from 'react';

import './style.css';

import BannerImg from "../../../assets/img/Lojas/banner.webp"
import FormCep from '../../../Components/FormCep';

function Banner() {
    return (
        <section className="pt-0 background-style"
                  style={{ backgroundImage:
                      `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerImg})` }}>
            <div className="container company-banner ptb-50-m">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h1 className="c-white text-center">Nossas Lojas.</h1>
                            </div>
                        </div>
                        <div className="row mt-20 justify-content-center">
                            <div className="col-md-6 text-center">
                                <span className="c-white fs-14 fw-600 font-italic">Tem sempre uma LOViZ pertinho de você.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;