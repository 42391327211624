import React from "react";

import './styles.css'

function Compliance(){
    return(
        <>
            <section id="pre-compliance" className="termos-uso-politica-privacidade bg-gray mb-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="content">
                                <h1 className="mt-4 mb-2 text-center">Compliance</h1>
                                <p className="text-center fw-600">Política Anticorrupção e Combate à Lavagem de
                                    Dinheiro</p>
                                <p className="text-center fw-600"> POLÍTICA CORPORATIVA – JANEIRO / 2019</p>
                            </div>
                            <div className="content">
                                <blockquote>
                                    <p className="fs-18 pl-32 font-italic">“Não pode existir crime mais sério que a
                                        corrupção. Outras
                                        ofensas violam uma lei, enquanto a corrupção viola a fundação de todas as
                                        leis.</p>
                                    <p className="fs-18 pl-32 font-italic">Não pode existir um delito maior que aquele
                                        no qual o representante eleito vende
                                        esta sagrada confiança para o seu próprio benefício e enriquecimento; tão pouco
                                        é menor o delito cometido por aquele que corrompe. Este é pior do que o ladrão,
                                        pois o ladrão rouba o indivíduo, enquanto o político corrupto saqueia uma cidade
                                        ou um Estado inteiro.</p>
                                    <p className="fs-18 pl-32 font-italic">Ele é tão perverso quanto o assassino, pois o
                                        assassino tira uma vida contra a
                                        lei, enquanto o corrupto e o aquele que o corrompe tem por objetivo o
                                        assassinato do Estado democrático em si.</p>
                                    <p className="fs-18 pl-32 font-italic">(…) Se nós falharmos em fazer tudo o que
                                        pudermos para estancar a corrupção, nós
                                        não vamos escapar a nossa parcela de responsabilidade na culpa (…)”</p>
                                    <p className="fs-18 text-right">Theodore Roosevelt</p>
                                    <p className="fs-18 text-right">26° presidente dos Estados Unidos.</p>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="compliance" className="compliance">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="content">
                                <ol>
                                    <li>
                                        <h4 className="fs-6">EXPOSIÇÃO DE MOTIVOS</h4>
                                        <p>Compliance: deriva do verbo inglês “to comply”, que significa dever de
                                            cumprir, isto é, estar em conformidade e fazer cumprir leis, decretos,
                                            regulamentos e instruções aplicáveis à atividade da LOViZ, que, na hipótese
                                            de não cumprimento, podem gerar sanções, perda financeira e danos à
                                            reputação/imagem.</p>
                                        <p>A presente Política Anticorrupção (doravante denominada a “Política”)
                                            contempla principalmente o necessário cumprimento dos requisitos legais
                                            anticorrupção e o desenvolvimento, entre outros, do princípio geral de
                                            integridade consagrado nos Princípios de Atuação da LOViZ, como norma
                                            fundamental que evidencia o compromisso de agir em consonância com os
                                            princípios éticos mais exigentes, transmitindo a importância da integridade
                                            empresarial a todos os conselheiros, diretores e empregados da empresa. Tais
                                            Princípios de Atuação impulsionam a empresa para assumir um compromisso
                                            público com a gestão responsável, que vai além da conformidade legal, assim
                                            como para fomentar e garantir o respeito aos valores contidos neles entre os
                                            seus conselheiros, diretores, empregados e parceiros comerciais.</p>
                                        <p>Em conformidade com as principais referências internacionais sobre
                                            responsabilidade das empresas e anticorrupção – como as recomendações da
                                            OCDE; a Foreign Corrupt Practices Act (FCPA), dos EUA; a Bribery Act, do
                                            Reino Unido; ou as reformas de legislações penais do Brasil e outros países
                                            – e de acordo com os Princípios de Atuação anteriormente mencionados, é
                                            importante para a empresa zelar para que não sejam permitidas, em seu
                                            âmbito, certas condutas que atentam contra tal integridade empresarial, que
                                            possam influenciar em uma decisão empresarial ou incorrer em eventuais
                                            conflitos de interesse que possam sobrepor prioridades pessoais às
                                            coletivas; promovendo, desta forma, que todas as pessoas que se integram em
                                            sua atividade, se comportem com retidão, sem buscar, em nenhum caso,
                                            benefício ilegítimo para a empresa, próprio ou de terceiros, através do uso
                                            indevido de sua posição ou seus contatos.</p>
                                        <p>Esta Política estabelece pautas de comportamento no tocante à aceitação ou
                                            oferta de presentes ou convites, proibindo qualquer tipo de suborno. Não é
                                            permitido prometer, oferecer ou dar qualquer benefício ou vantagem de
                                            qualquer natureza, de forma direta ou indireta (através de um terceiro), com
                                            a finalidade de influenciar em decisões de qualquer tipo (incluindo as
                                            governamentais, administrativas ou judiciais) ou obter vantagens indevidas
                                            para a empresa. Também é proibido aceitar qualquer benefício ou vantagem que
                                            possa resultar em descumprimento das obrigações e deveres dos conselheiros,
                                            diretores e empregados da empresa.</p>
                                        <p>A LOViZ, apesar de não atuar como banco ou instituição financeira, mas como
                                            empresa prestadora de serviços de engenharia de telecomunicações, dispõe
                                            também sobre Prevenção à Lavagem de Dinheiro, no anseio de preservar
                                            quesitos básicos de segurança e manter padrões responsáveis em suas
                                            operações administrativas junto aos clientes.</p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">ÂMBITO DE APLICAÇÃO E INTERPRETAÇÃO</h4>
                                        <p>Esta Política serve como parâmetro mínimo e deverá ser observada por todos os
                                            conselheiros, diretores, empregados e estagiários da empresa, em qualquer
                                            dos territórios nos quais esta estiver presente, assim como pelos terceiros
                                            que intermediarem, colaborarem e/ou participarem de negócios em nome da
                                            empresa.</p>
                                        <p>Todos os profissionais da LOViZ, dentro de suas atividades e independente de
                                            suas posições hierárquicas, têm funções e responsabilidade relacionadas à
                                            prevenção à lavagem de dinheiro. A estrutura organizacional mencionada a
                                            seguir, está identificada diretamente com as responsabilidades de cada um
                                            dos Profissionais com as condutas de prevenção aos crimes de lavagem de
                                            dinheiro e respeito à legislação.</p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">DEFINIÇÕES</h4>
                                        <p>Objeto de Valor: qualquer elemento de valor tangível ou intangível, definido
                                            amplamente, em qualquer forma, incluindo, mas não limitado a: dinheiro,
                                            equivalente a dinheiro (tais como cartões de presente e desconto em
                                            produtos), empréstimos, presentes, convites, bens móveis ou imóveis,
                                            serviços, empregos para familiares, viagens, estadia, entretenimento,
                                            refeições, reembolso de despesas, favores, oportunidades de negócio ou
                                            emprego, cumprimento da solicitação de entregar algo de valor a um terceiro,
                                            contribuições para fins beneficentes ou para outras organizações sem fins de
                                            lucro e patrocínios promocionais.</p>
                                        <p>Funcionário/Empregado Público: refere-se a: (i) qualquer funcionário público
                                            ou eleito, agente, empregado (independentemente da posição) ou pessoa que
                                            atue em nome de um governo nacional, estadual ou municipal, departamento,
                                            agência, órgão, companhia de propriedade ou controle estatal, organização
                                            pública internacional, partido político ou entidade financiada
                                            majoritariamente por fundos públicos, que geralmente é percebida como
                                            entidade que realiza funções governamentais, ou que têm executivos chave ou
                                            diretores nomeados por um governo; e (ii) qualquer partido político,
                                            candidato político a um cargo ou qualquer pessoa que atue em nome do partido
                                            ou do candidato a cargo político. A título de exemplo, incluem-se: emissores
                                            de licenças governamentais, aprovações ou licenças, (seja em nível
                                            internacional, nacional, regional, municipal etc.), autoridades
                                            aeroportuárias, empregados e diretores de empresas de propriedade estatal,
                                            alfândegas, agentes de imigração ou tributários, ou ministros ou
                                            representantes de governos nacionais ou estrangeiros.</p>
                                        <p>Parceiro Comercial: terceiros atuando em nome ou por conta da empresa ou de
                                            qualquer entidade de propriedade, ou controlada pela empresa, que possam
                                            interagir com agentes externos, especialmente se estes forem
                                            Funcionários/Empregados; ou qualquer parceria na qual a empresa tenha
                                            interesses econômicos, nos quais intervenham, entre outros, por exemplo:
                                            agentes, brokers, intermediários, assessores, consultores, representantes,
                                            sócios de joint-ventures, co-investidores, franqueados, fornecedores
                                            autorizados, agências de viagem, transportadores autorizados ou agentes de
                                            alfândega, advogados ou lobistas atuando em nome ou por conta da
                                            empresa.</p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">ÂMBITO DE APLICAÇÃO E INTERPRETAÇÃO</h4>
                                        <p>4.1 Proibições gerais</p>
                                        <p>4.1.1. É proibido oferecer, promover, pagar, dar ou autorizar a entrega de
                                            qualquer Objeto de Valor, direta ou indiretamente (através de um terceiro),
                                            a:</p>
                                        <p>– qualquer Funcionário / Empregado, diretores, gerentes e estagiários ou
                                            qualquer pessoa física ou jurídica (pública ou privada), com a finalidade de
                                            influenciar indevidamente em uma decisão de um Funcionário / Empregado, para
                                            obter ou manter um negócio ou qualquer outra vantagem. Esta proibição é
                                            aplicável independentemente de se o pagamento é para facilitar, acelerar ou
                                            agilizar trâmites.</p>
                                        <p>4.1.2. É proibido aos funcionários, diretores, gerentes ou estagiários
                                            oferecer, prometer, pagar, dar, autorizar a entrega a terceiros ou aceitar e
                                            receber quaisquer Objeto de Valor de tal relevância que possam condicionar
                                            ilicitamente a conduta de quem recebe tal Objeto de Valor.</p>
                                        <p>4.1.3 Nenhum gerente, diretor ou empregado poderá se beneficiar, nas compras
                                            privadas que forem feitas de Parceiros Comerciais ou fornecedores, de
                                            descontos ou condições vantajosas que excedam as condições gerais aplicáveis
                                            com caráter geral aos empregados da respectiva empresa.</p>
                                        <p>4.1.4. É proibido oferecer, prometer, pagar, dar ou autorizar a entrega de
                                            qualquer Objeto de Valor a terceiros, sabendo que, com uma alta
                                            probabilidade, os terceiros, por sua vez, oferecerão, prometerão ou
                                            proporcionarão qualquer vantagem proibida na presente seção 4.1.</p>
                                        <p>Fica expressamente proibido o uso de fundos próprios ou de terceiros para se
                                            esquivar aos objetivos desta Política.</p>
                                        <p>4.2 Práticas aceitáveis</p>
                                        <p>Os presentes e convites de negócios nunca devem ser oferecidos ou aceitos com
                                            propósitos ilícitos e devem, em todos os casos:</p>
                                        <ul>
                                            <li>
                                                Estar em conformidade com as leis e regulamentações, tanto no país de
                                                quem faz o convite quanto no de quem o recebe.
                                            </li>
                                            <li>
                                                Ser entregues ou aceitos sem esperar reciprocidade: a intenção ou
                                                propósito do presente ou convite deve ser simplesmente construir a
                                                relação de negócio dentro de padrões normais de cortesia e não
                                                influenciar na pessoa encarregada de adotar uma determinada decisão de
                                                negócio.
                                            </li>
                                            <li>
                                                Ser socialmente aceitáveis: outras pessoas (tais como colegas,
                                                concorrentes ou imprensa) deveriam estar de acordo que o presente ou
                                                convite é razoável e que poderia ser comunicado sem temer uma possível
                                                rejeição social.
                                            </li>
                                            <li>
                                                Ser consistentes com os interesses dos negócios da empresa, assim como
                                                com as práticas habituais do negócio.
                                            </li>
                                            <li>
                                                Ser ocasionais e não excessivos, conforme os padrões locais ou da
                                                indústria.
                                            </li>
                                            <li>
                                                Ser registrados em documentação precisa, adequada e com detalhamento
                                                razoável.
                                            </li>
                                            <li>
                                                Sempre dar ciência ao superior imediato do presente recebido, sob pena
                                                de incorrer nas hipóteses descritas como imorais.
                                            </li>
                                        </ul>
                                        <p>Não será considerada irregular a aceitação por parte dos gerentes, diretores
                                            e empregados da empresa, dentro dos usos sociais empresariais, de convites
                                            para eventos promocionais ou de negócio, nos quais a entidade ou a empresa
                                            organizadora (diferente da LOViZ) assuma as despesas razoáveis de
                                            deslocamento, estadia e/ou manutenção, desde que o objetivo de tal convite
                                            seja exclusivamente a apresentação de produtos ou serviços de tal entidade
                                            ou empresa organizadora e o convite não seja individual, mas sim dirigido a
                                            um coletivo de clientes ou potenciais clientes.
                                        </p>
                                        <p>Também é aceitável que a LOViZ , dentro dos usos sociais empresariais,
                                            assumam despesas diretamente relacionadas com eventos promocionais ou
                                            demonstrações de produtos ou serviços objeto do negócio e, em particular,
                                            convidem terceiros, assumindo as respectivas despesas razoáveis de
                                            deslocamento, estadia e/ou manutenção, desde que não tenham a finalidade de
                                            conseguir qualquer vantagem proibida na seção 4.1 acima.
                                        </p>
                                        <p>Da mesma forma a LOViZ poderá assumir despesas razoáveis, diretamente
                                            relacionadas com eventos de formação ou fóruns de capacitação, incluindo as
                                            de deslocamento, estadia e/ou manutenção, desde que não tenham como
                                            finalidade conseguir qualquer vantagem proibida na seção 4.1 acima.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">REGISTROS CONTÁBEIS E FINANCEIROS</h4>
                                        <p>A empresa exige que seja mantido um sistema de controles internos contábeis
                                            adequados e que sejam reportadas e refletidas todas as transações de forma
                                            adequada, precisa e com o detalhamento razoável nos livros e registros
                                            contábeis da empresa; por isso, os livros contábeis não devem conter
                                            registros nem declarações falsas ou enganosas, tais como registrar uma
                                            despesa por presentes ou convites como algo diferente de sua natureza. Nunca
                                            deverão ser registradas intencionalmente transações de forma equívoca no
                                            tocante à contas contábeis, departamentos ou período contábil. Será mantida
                                            documentação precisa, apropriada e com detalhamento razoável para dar
                                            suporte a todas as transações e serão preservados os documentos conforme as
                                            políticas da empresa sobre gestão de informação e documentos.
                                        </p>
                                        <p>Todos os Profissionais da LOViZ envolvidos nas etapas que digam respeito às
                                            operações financeiras, deverão observar os aspectos voltados à prevenção à
                                            lavagem de dinheiro, definida nesta Política e em todas os dispositivos e
                                            regulamentos em vigor.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">PARCEIROS COMERCIAIS</h4>
                                        <p>A empresa conta com diversas normas que estabelecem disposições obrigatórias
                                            sobre as relações com fornecedores e Parceiros Comerciais.
                                        </p>
                                        <p>A empresa não contratará nem realizará negócios com um Parceiro Comercial se
                                            achar que há um risco de que este venha a violar as leis aplicáveis em
                                            matéria de anticorrupção ou as proibições estabelecidas nesta Política.
                                        </p>
                                        <p>Antes de estabelecer uma relação de negócios com qualquer Parceiro Comercial
                                            que vá interagir em nome da empresa, especialmente se for interagir com
                                            Funcionários/Empregados, a área de Compras (no caso de negociações dentro do
                                            seu âmbito de atuação) ou a área Gestora (em contratações fora do âmbito da
                                            área de Compras) deverão realizar uma avaliação adequada do Parceiro
                                            Comercial a esse respeito e se certificaram de que:
                                        </p>
                                        <p>(a) o contrato do Parceiro Comercial contém obrigações e garantias
                                            anticorrupção e combate à lavagem de dinheiro, assim como o direito da
                                            LOViZ de dar por encerrado o contrato em caso de descumprimento de tais
                                            obrigações e garantias;
                                        </p>
                                        <p>(b) o Parceiro Comercial certifica o cumprimento das leis em matéria de
                                            anticorrupção e combate à lavagem de dinheiro;
                                        </p>
                                        <p>(c) a empresa mantém os respectivos registros relacionados com a contratação,
                                            certificação e avaliação do Parceiro Comercial.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">COOPERAÇÃO DE GERENTES, DIRETORES E EMPREGADOS, FORMAÇÃO E
                                            CERTIFICAÇÕES</h4>
                                        <p>Cooperação de gerentes, diretores e empregados: esta Política é de
                                            cumprimento obrigatório para todos os gerentes, diretores e empregados da
                                            empresa, devendo colaborar de forma completa e sincera, naquilo que lhes for
                                            requerido a este respeito.</p>
                                        <p>Formação: dependendo das suas responsabilidades, a empresa pode solicitar a
                                            assistência de gerentes, diretores e empregados para a formação relacionada
                                            com esta Política.
                                        </p>
                                        <p>Certificações: os gerentes e diretores da empresa, como responsáveis por
                                            estabelecer adequados controles e procedimentos para garantir o cumprimento
                                            dessa Política, deverão certificar anualmente (ou, em qualquer caso, antes
                                            de encerrar suas atividades dentro da empresa ou quando assim for requerido
                                            pela empresa) o cumprimento do estabelecido nesta Política, dentro do seu
                                            âmbito de responsabilidade, conforme o modelo estabelecido no Anexo 1.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">DAS ÁREAS DE RH TECNOLOGIA, SUPORTE E DEMAIS SETORES</h4>
                                        <p>As equipes que atuem nas áreas de RH e Atendimento, ficarão responsáveis por
                                            garantir que todos os demais profissionais estão atualizados das informações
                                            e de todos os treinamentos que a LOViZ julgar relevante para manutenção da
                                            referida Política de Prevenção à Lavagem de Dinheiro. Ainda, ficará sobre
                                            responsabilidade do setor de RH e Atendimento, garantir pleno atendimento
                                            aos clientes cadastrados, preservando a transparência e os motivos pelos
                                            quais exigem informações e retém dados cadastrais e referentes a eventuais
                                            operações financeiras/pagamentos.

                                        </p>
                                        <p>Os profissionais que atuam no setor de Tecnologia da Informação e Suporte,
                                            serão responsáveis por garantir que todos os sistemas online/off-line da
                                            LOViZ, irão operar de forma plena, sendo que, nos casos fortuitos ou de
                                            força maior que venham a ocorrer serão informados no primeiro momento
                                            possível ao cliente. Ainda, nas hipóteses de ajustes e revisões do sistema
                                            que deixem os serviços inacessíveis ou prejudicados, deverá ser informado ao
                                            setor de atendimento, para que os clientes também sejam informados.
                                        </p>
                                        <p>Todos os profissionais que não estejam aqui elencados em algum dos setores
                                            supracitados, deverão respeitar as normas vinculadas ao demais, preservando
                                            a validade desta Política de Prevenção à Lavagem de Dinheiro da LOViZ e,
                                            sempre que identificada atividade suspeita de profissionais ou clientes,
                                            reportar de imediato ao superior hierárquico que lhe diga respeito ou que
                                            seja necessário.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">REGIME DISCIPLINAR</h4>
                                        <p>É responsabilidade de todos e cada um dos gerentes, diretores e empregados o
                                            estrito cumprimento do estabelecido no presente e é responsabilidade dos
                                            conselheiros e diretores explicar e divulgar esta Política entre os seus
                                            colaboradores e supervisionar o seu cumprimento.
                                        </p>
                                        <p>Serão implantados mecanismos de supervisão para comprovar a aplicação do
                                            estabelecido nesta Política. Se houver qualquer evidência de possíveis
                                            descumprimentos do estabelecido no presente documento, será realizada a
                                            devida investigação. Serão realizadas auditorias periódicas e enviados
                                            relatórios anuais sobre os resultados à respectiva Comissão de Auditoria.
                                        </p>
                                        <p>A violação do estabelecido nesta Política será considerada uma infração
                                            sujeita à ação disciplinar determinada pela Diretoria de Recursos Humanos,
                                            após sua devida análise e de acordo com o regime de trabalho aplicável,
                                            sendo que qualquer forma de corrupção é da maior gravidade.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">ÓRGÃO DE CONSULTA E COMUNICAÇÕES</h4>
                                        <p>Caso um gerente, diretor ou empregado tenha dúvidas relacionadas com o
                                            cumprimento, aplicação ou interpretação desta Política, este deverá enviar
                                            um e-mail para ouvidoria@loviz.com.vc para receber orientações e
                                            esclarecimentos do Departamento de Princípios e Atuação. Espera-se que toda
                                            pessoa que saiba de qualquer infração ou suspeita de infração desta Política
                                            comunique o fato através do mesmo e-mail – ouvidoria@loviz.com.vc.
                                            Agradecemos esta cooperação e, de acordo com as suas políticas, proíbe a
                                            adoção de qualquer represália contra as pessoas que fizerem estas
                                            comunicações de boa fé.
                                        </p>
                                    </li>
                                    <li>
                                        <h4 className="fs-6">ENTRADA EM VIGOR</h4>
                                        <p>Os princípios gerais desta Política entrarão em vigor a partir da sua
                                            aprovação pela LOViZ.
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Compliance;