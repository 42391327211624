import React from 'react';
import Img1 from '../../../assets/img/01-Foto-esquerda-DOG.jpg';
import Img2 from '../../../assets/img/home-wifi-puck2.png';
import Img3 from '../../../assets/img/03-Foto-direita-COZINHA.jpg';
import ButtonCall from '../../../Components/ButtonCall';
import './style.css';

export default function WifiHome() {
    return (
        <section id="wifi-section">
            <div className="container">
                {/***  Class Row-1 ***/}
                <div className="row mb-20">
                    <div className="col-md-12 text-center">
                        <h2 className="section-title">O Wi-Fi que preenche sua casa.</h2>
                    </div>
                </div>
                {/*** Class Row-2 ***/}
                <div className="row justify-content-center">
                    <div className="col-md-12 position-relative d-flex justify-content-center">
                        <img src={Img2} alt="imagem-1" className="principal-image" />
                        <img src={Img1} alt="imagem-1" className="dog secondary-image" />
                        <img src={Img3} alt="imagem-1" className="pan secondary-image" />
                    </div>
                </div>
                {/*** Class Row-3***/}
                <div className="row justify-content-center mt-50">
                    <div className="col-md-6 text-center">
                        <p className="fs-15">Cobertura Wi-Fi em cada cantinho da sua casa com o Super Wi-Fi Onboard.
                            Conexão estável e segura para você e seus equipamentos.</p>
                    </div>
                </div>
                {/*** Class Row-4**/}
                <div className="row justify-content-center mt-20">
                    <div className="col-md-12 text-center">
                        <ButtonCall title="Saiba mais" color="#1a73e9ff" />
                    </div>
                </div>

            </div>
        </section>
    );
}