import React from "react";
import img1 from "../../assets/img/IOT/img_carousel_1.jpg";
import img2 from "../../assets/img/IOT/img_carousel_2.jpg";
import img3 from "../../assets/img/IOT/img_carousel_3.jpg";
import Information from "../../Parts/Iot/Information";
import Introudction from "../../Parts/Iot/Introudction";
import Carousel from "../../Components/CustomCarousel";

export default function Iot() {
  const data = [
    {
      title: "Segurança",
      description: "A tecnologia smart a serviço da segurança de sua família",
      image: img1,
    },

    {
      title: "Comodidade",
      description:
        "Faça compras de supermercado pela sua geladeira. Crie listas de compras e pesquise preços",
      image: img2,
    },

    {
      title: "Interatividade",
      description:
        "Assistentes virtuais como a Alexa, a Siri e outros, já fazem parte das casas de milhares de pessoas",
      image: img3,
    },
  ];
  return (
    <>
      <Introudction />
      <Information />
      <Carousel
        sectionTitle="Por que <span class='c-primary'> conectar tudo ? <span>"
        items={data}
      />
    </>
  );
}
