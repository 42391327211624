import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import axios from "axios";
import {ReCAPTCHA} from "react-google-recaptcha";
import { Alert } from "react-bootstrap";
import InputMask from "react-input-mask";

export default function FormCPF({
  sectionClass = "",
  parentColumnSize = "7",
  hideText = false,
  responsiveRadioColumn = "col-2 col-sm-12 col-md-12 col-lg-2",
  responsiveFieldsColumn = "col-8 col-sm-12 col-md-12 col-lg-8",
  responsiveButtonColumn = "col-2 col-sm-12 col-md-12 col-lg-2 text-right",
  componentText = "Digite seu CPF/CNPJ para gerar a 2º via da sua conta.",
  containsWhatsAppColumn = false,
}) {
  useEffect(() => {
    const anime = document.querySelectorAll("[data-anime]");
    anime.forEach((el, index) => {
      setTimeout(() => el.classList.add("active"), index * 10);
    });
  }, []);
  const [selectedOption, setSelectedOption] = useState("cpf");

  const [formValue, setFormValue] = useState({
    cpf: '',
  });
  const [value, setValue] = useState("");

  const [showAlert, setShowAlert] = useState({
    show: false,
    status: '',
    header: '',
    message: ''
  });

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value
    });
    setValue(event.target.value);
  }
  
  function handleButtonClick(option) {
    setSelectedOption(option);
    console.log(`Option selected: ${option}`);
  }
  const captchaRef = useRef(null);
  const mask = selectedOption === "cpf" ? "999.999.999-99" : "99.999.999/9999-99";

  const handleSubmit = async (e) => {
    // e.currentTarget.disabled = true;
    e.preventDefault();
    const strAddress = formValue.cpf.replace(/\./g, '');
    const formCPF = strAddress.replace(/-/g, '');
    const alertFooter = document.getElementById('alert-footer')
    // eslint-disable-next-line no-template-curly-in-string
    axios.get(`https://lovizhub.loviz.com.vc/segunda-via?cpfCnpj=${formCPF}`)
      .then(response => {
        // console.log(response)
        if (response.data.status === 'not_found_client') {
          if(alertFooter !== null){
            alertFooter.innerHTML = "";
          }
          setShowAlert({
            show: true,
            status: 'warning',
            header: 'Ops! Ocorreu um erro!',
            message: 'Não encontramos seu CPF/CNPJ no nossos sistema, tente novamente ou entre em contato pelo whatsapp.'
          })
        } else if (response.data.status === 'not_found_bill') {
          if(alertFooter !== null){
            alertFooter.innerHTML = "";
          }
          setShowAlert({
            show: true,
            status: 'success',
            header: 'Está tudo certo!',
            message: 'Encontramos seus dados mas você não possui nenhuma pendência no nosso sistema!'
          })
        } else if (response.data.list.length >= 1) {
          if(alertFooter !== null){
            alertFooter.innerHTML = "";
          }
          setShowAlert({
            show: true,
            status: 'danger',
            header: 'Foram encontradas pendências em seu CPF!',
            message: 'As seguintes pendências encontradas foram'
          })
          if(alertFooter !== null){
            alertFooter.innerHTML = "";
          }
          response.data.list.forEach(myFunction)
          function myFunction(item) {
            let div = document.getElementById('alert-footer')
            let anchor = document.createElement('a')
            let text = document.createTextNode('Visualizar 2º via')
            let spanText = document.createTextNode(`Conta vencida em ${item.vencimento} no valor de R$ ${item.valor}`)
            let span = document.createElement('p')
            span.appendChild(spanText)
            anchor.appendChild(text)
            anchor.href = item.url
            anchor.target = "_blank"
            anchor.className = 'fonts-button blue-button text-white'
            div.append(span)
            div.append(anchor)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <section className={`${sectionClass}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className={`col-md-${parentColumnSize}`}>
            <div className="form-section-cep text-left" data-anime>
              {!hideText ? (
                <div className="row">
                  <div className="col-md-12">
                    <span className="font-italic c-gray fs-18">
                      {componentText}
                    </span>
                  </div>
                </div>
              ) : null}
              <form className="row form-cep mt-30 ">
              
              <div className={`${responsiveRadioColumn} d-flex align-items-center`}>
                        <button
                          type="button"
                          name="option"
                          value="cpf"
                          onClick={() => handleButtonClick("cpf")}
                          className={`fonts-button cpf-button ${selectedOption === "cpf" ? "blue-button" : "outline-blue-button"}`}
                        >CPF</button>
                        <button
                          type="button"
                          name="option"
                          value="cpf"
                          onClick={() => handleButtonClick("cnpj")}
                          className={`fonts-button cpf-button ${selectedOption === "cnpj" ? "blue-button" : "outline-blue-button"}`}
                        >CNPJ</button>
                    </div>
                <div
                  className={`${responsiveFieldsColumn}`}
                >
                 
                  <InputMask onChange={handleChange} className="cep-input fonts-input w-100"
                   name="cpf" mask={mask} />
                </div>
                <div className={`${responsiveButtonColumn} submit-form d-flex align-items-center`}>
                  <button className="fonts-button blue-button submit-button-cep"
                    onClick={handleSubmit}
                  >
                    Gerar
                  </button>
                </div>
              </form>
              {
                showAlert.show ?
                  (

                    <Alert className="mt-5 .alert-dismissible fade show" variant={showAlert.status} onClose={() => setShowAlert({ show: false })}>
                      <Alert.Heading>{showAlert.header}</Alert.Heading>
                      <p className="mt-3">{showAlert.message}</p>
                      <div className="mt-3 d-flex flex-column align-items-start gap-3" id="alert-footer"></div>
                    </Alert>

                  ) : ''
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
