import React, { useEffect, useRef } from "react";

import { Link } from "react-router-dom";

export default function List({
  title,
  content,
  image,
  isInverted /* For default return the image first and after the content, when you invert the order the content comes first and image after.*/,
  containLink /* The value must be Boolean, so if you set true it'll show the link */,
  linkText = "Saiba mais",
  index,
  linkTo = "super-wifi",
  id,
}) {

  const link = containLink ? (
    linkTo.indexOf("/") === 0 ? (
      <Link to={linkTo} className="c-primary fs-14 mobile-fs-14">
        {linkText}
      </Link>
    ) : (
      <a href={linkTo} className="c-primary fs-14 mobile-fs-14" target="_blank" rel="noreferrer">
        {linkText}
      </a>
    )
  ) : null;

  return (
    <div id={id}
      className={`row mt-20 mobile-mt-40 justify-content-around ${
        isInverted ? "flex-row-reverse" : null
      }`}
      key={index}
    >
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12">
            <h1 className="c-dark fs-20 fw-600 ptb-20 font-italic mobile-ptb-10 ">
              {title}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p
              className="c-dark fs-14 wt-460 c-gray font-italic"
              id="width-text"
            >
              {content} 
              {link}
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12">
            <img
              src={image}
              alt={image}
              className="border-radius-12 mobile-img mobile-img-item"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
