import React from "react";
import "./style.css";
import img1 from "../../../assets/img/Socios/Torne-se-nosso-socio.webp";
import img2 from "../../../assets/img/Socios/Pitch-deck.webp";
import img3 from "../../../assets/img/Socios/Power-branding.webp";
import img4 from "../../../assets/img/Socios/Nosso-modelo-de-gestao.webp";
import SecondSectionItems from "../../../Components/SecondSectionItems";

export default function Announcement() {
  const item = [
    {
      title: `Torne-se nosso sócio`,
      content: `Quer investir e crescer com a LOViZ? Cadastre-se e
      comece agora mesmo a investir em um negócio
      inovador, com crescimento sólido e ótima
      rentabilidade. Dúvidas em como investir?`,
      image: img1,
      isInverted: false,
      linkText: "Fale com um de nossos Consultores",
      containLink: true,
      linkTo: "https://api.whatsapp.com/send?phone=5508000800999&text=Falar%20com%20um%20consultor"
    },
    {
      title: `Pitch deck`,
      content: `Não faltam motivos para investir na LOViZ e crescer
      com a gente. Acompanhe nosso pitch deck com
      dados e informações detalhadas e se convença da
      oportunidade de investir e fazer a diferença por um
      mundo melhor ao nosso lado.`,
      image: img2,
      isInverted: true,
      linkText: "Solicitar Pitch Deck",
      containLink: true,
      linkTo: 'mailto:investimento@loviz.com.vc?Subject=Pitch Deck'
    },
    {
      title: `Power branding`,
      content: `Inspiradora, revolucionária e emocional. A força e
      consistência da marca LOViZ é reproduzida na
      prestação de um serviço inovador, percebido por
      consumidores e mercado, e que consequentemente
      fortalece seu reconhecimento de marca e gera valor
      a investidores e acionistas.`,
      image: img3,
      isInverted: false,
      linkText: "",
      containLink: false,
    },
    {
      title: `Nosso modelo de gestão`,
      content: `Com base em várias técnicas de crescimento rápido
      de startups, a LOViZ segue um modelo próprio de
      gestão: o growth scaling! Nesse modelo seguimos
      uma trajetória de crescimento altamente planejada
      e acelerada. Dividido em fases de desenvolvimento,
      as prioridades e gestão de cada estágio seguem
      claras e pré-definidas, objetivando sempre o
      escalonamento das operações e o mercado global.`,
      image: img4,
      isInverted: true,
      linkText: "",
      containLink: false,
    },
  ];
  return (
    <section id="announcement-socios">
      <div className="container">
        <div className="row mt-50">
          <div className="col-md-12">
            <h1 className="c-dark section-title fw-600 text-center">
              Próximos passos rumo ao sucesso.
            </h1>
          </div>
        </div>
        {item.map(
          ({ title, content, image, isInverted, containLink, linkText, linkTo }) => (
            <SecondSectionItems
              title={title}
              content={content}
              image={image}
              isInverted={isInverted}
              containLink={containLink}
              linkText={linkText}
              linkTo={linkTo}
            />
          )
        )}
      </div>
    </section>
  );
}
