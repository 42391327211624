import React from "react";
import "./style.css";

export default function ContactUs({
    iframe,
    link,
  title = "Entre em contato com a gente",
  subtitle = (
    <p>
      Clicando em “enviar”, você concorda em ser contatado por nossa equipe e
      receber e-mails e mensagens promocionais, podendo se descadastrar a
      qualquer momento se mudar de ideia. Para mais informações, veja nossas{" "}
      <a href="/politica-privacidade">
        Política de Privacidade
      </a>
    </p>
  ),
}) {
  return (
    <section id="contact-form">
      <div className="container">
        {/**  Row-1 **/}
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <h2 className="section-title fs-40">{title}</h2>
          </div>
        </div>
          <div className="col-md-12">
            <div className="mt-30 row justify-content-center">
              <div className="col-md-6 col-lg-4">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    <a className="btn btn-primary btn-lg text-white" onClick={()=>window.dataLayer.push({'event': 'Entrar em Contato'})}
                    href={link} target="_blank"
                     rel="noreferrer">Entrar em contato</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
  );
}
