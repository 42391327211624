import React from "react";

export default function AnnouncementItems({
  image,
  alt,
  title,
  content,
  index,
}) {
  return (
    <div className="col-md-6" key={index}>
      <div className="row">
        <div className="col-md-12">
          <img src={image} alt={alt} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h2 className="text-center fs-20 fw-600 font-italic mt-10 c-dark">
            {title}
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <p className="text-center fs-15 fw-600 font-italic mt-20 c-gray">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
}
