import React from 'react';

import './style.css';

import HowItWorksImg from '../../../assets/img/Phone/WIFI-TEL-PHONE.svg';

function HowItWorks() {
    return (
        <section id="how-it-works">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title text-center">Como funciona</h2>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-20">
                            <div className="col-md-6 text-center">
                                <span className="section-description c-gray">
                                    O Telefone LOViZ é como um telefone residencial típico, exceto que seu serviço telefônico é fornecido pela Internet e é alimentado pela LOViZ. Use seu número de telefone residencial atual ou escolha um novo.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-50">
                    <div className="col-md-9">
                        <img src={HowItWorksImg} alt="How it works" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorks;