import React from 'react';

import FirstSection from '../../Parts/Phone/FirstSection';
import HowItWorks from '../../Parts/Phone/HowItWorks';
import Benefits from '../../Parts/Phone/Benefits';
import StartNow from '../../Parts/Phone/StartNow';

import './style.css'

function Phone() {
    return (
        <div id="phone-page" className="mt-5">
            <FirstSection />
            <HowItWorks />
            <Benefits />
            <StartNow />
        </div>
    );
}

export default Phone;