import React from "react";

import Banner from "../../Parts/Lojas/Banner";
import Maps from "../../Parts/Lojas/Maps";

function Lojas(){
    return(
        <div>
            <Banner />
            <Maps />
        </div>
    )
}

export default Lojas