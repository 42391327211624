import React from "react";

import Banner from "../../Components/Banner";
import CustomCarousel from "../../Components/CustomCarousel";

import BannerBackground from "../../assets/img/Education/banner.webp";
import Item1 from "../../assets/img/Education/item-1.webp";
import Item2 from "../../assets/img/Education/item-2.webp";
import Item3 from "../../assets/img/Education/item-3.webp";

import "./style.css";

function Education() {
    const items = [
        {
            image: Item1,
            title: "Escolas Públicas",
            description:
                "Educação para todos é um direito. Educação com internet rápida e gratuita para escolas públicas é nossa missão.",
        },
        {
            image: Item2,
            title: "Universidades",
            description:
                "Futuros líderes e executivos iniciam suas jornadas na sala de aula...e com conexão LOViZ.",
        },
        {
            image: Item3,
            title: "Governo",
            description:
                "Cidadania é apoiar a iniciativa pública e suas demandas em segurança pública, cyber segurança institucional e conexão.",
        },
    ];
    return (
        <div id="education-page">
            <Banner
                background={BannerBackground}
                backgroundOverlay="transparent, rgb(0, 0, 0, .5) 60%"
                classPrincipalTitleColor="c-white text-right"
                principalTitle="“O futuro da educação</br>está em nossas mãos."
                classFontColor="c-white text-right"
                backgroundPosition="center"
                description="Ou talvez no clique de um mouse. Levar internet gratuita de</br>alta qualidade para escolas públicas é nossa missão."
            />
            <CustomCarousel
                sectionTitle="Educação e <span class='c-primary'>Tecnologia</span>"
                items={items}
            />
            {/*<ContactUs link={''} />*/}
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                    <iframe title="Contato"
                            src="https://forms.monday.com/forms/embed/90bc78d4e77c08f2b556473db0d2c1a2?r=use1"
                            width="650" height="760"></iframe>
                </div>
            </div>
        </div>
    );
}

export default Education;
