import React from 'react';

import MeshNetworkImage from '../../../assets/img/mesh-network.webp'

import './style.css'

function MeshNetwork() {
    return (
        <section id="mesh-network">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="section-title fs-35">Conexão em <span className="c-primary">todos os cômodos</span><br />com uma rede mesh.</h2>
                    </div>
                </div>
                <div className="row mt-50">
                    <div className="col-md-4 offset-md-1 d-flex flex-column justify-content-between">
                        <div className="row wifi-row">
                            <div className="col-md-12">
                                <div>
                                    <h3 className="fs-25">Chega de desculpas por<br />aquele quarto com Wi-Fi ruim.</h3>
                                </div>
                                <div className="mt-20">
                                    <p className="c-gray fs-18 mb-0">Uma rede mesh protege você: com a Internet LOViZ de 1.000 MEGA, você poderá usar seus dispositivos em qualquer lugar da sua casa, porque todos os pontos de acesso são combinados pela tecnologia mesh.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row wifi-row mt-40">
                            <div className="col-md-12">
                                <div>
                                    <h3 className="fs-25">Pode se afastar do roteador.</h3>
                                </div>
                                <div className="mt-20">
                                    <p className="c-gray fs-18">Com o Super Wi-Fi da LOViZ, os pontos de acesso do seu Wi-Fi funcionam em conjunto para criar uma rede perfeita para toda a casa. Adicione até dois pontos do Super Wi-Fi, que normalmente cobrem até 278m².</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 d-flex justify-content-end">
                        {/*<iframe src="https://player.vimeo.com/video/637851874?autoplay=1&loop=1&autopause=0&background=1"*/}
                        {/*        width="480" height="680" frameBorder="0" allow="autoplay;"></iframe>*/}
                        <iframe src="https://player.vimeo.com/video/637863106?h=ebf7a1e891&amp;badge=0&amp;autoplay=1&loop=1&autopause=0&background=1"
                                width="480" height="680"
                                frameBorder="0" allow="autoplay;"
                                allowFullScreen></iframe>
                        {/*<iframe*/}
                        {/*    src="https://player.vimeo.com/video/637863106?h=d47b9b8604&color=f50a54&title=0&byline=0&portrait=0"*/}
                        {/*    width="480" height="680" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"*/}
                        {/*    allowFullScreen></iframe>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MeshNetwork;