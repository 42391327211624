import React from "react";

import "./style.css";


function Maps(){
    return(
        <div className="maps mt-20">
            <div className="map-text">
                <h2 className="c-primary">Localize a LOViZ mais próxima</h2>
            </div>
            <div>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3596.799857475844!2d-49.31356148498148!3d-25.64476728368997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dcff0a3bba66dd%3A0xded7450acdc9fe3c!2sR.%20Jequitib%C3%A1%2C%20105%20-%20Eucaliptos%2C%20Fazenda%20Rio%20Grande%20-%20PR%2C%2083820-026!5e0!3m2!1sen!2sbr!4v1642074230374!5m2!1sen!2sbr"
                width="1000" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"></iframe>
            </div>
        </div>
    )
}

export default Maps;