import React from "react";

function FooterCol({ title = "", children }) {
  return (
    <div className="col-sm menu-footer conheca-menu-footer">
      {title ? (
        <ul className="footer-list-title">
          <li>
            <a href="#">
              <strong>{title}</strong>
            </a>
          </li>
        </ul>
      ) : null}
      {children}
    </div>
  );
}

export default FooterCol;
