import React, {useState} from 'react';

import CustomModal from '../../../Components/CustomModal';

import EmailImg from '../../../assets/img/Contact/email.webp'
import PhoneImg from '../../../assets/img/Contact/smartphone.webp'
import WhatsAppImg from '../../../assets/img/Contact/whatsapp.webp'

function FirstSection() {
    const [showModal, setShowModal] = useState(false);
    const [showModal1, setShowModal1] = useState(false);
    return (
        <>
            {
                showModal
                    ?
                    <CustomModal title="Ligue para a LOViZ" setShowModal={setShowModal}>
                        <div className="row">
                            <div className="col-md-12">
                                <span>Atendimento telefônico 24h</span>
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col-md-12">
                                <a href="tel:+5508000800999" target="_blank" className="c-primary" rel="noreferrer">Ligue:
                                    0800 0800 999</a>
                            </div>
                        </div>
                    </CustomModal>
                    :
                    null
            }
            {
                showModal1
                    ?
                    <CustomModal title="Fale com a LOViZ" setShowModal={setShowModal1}>
                        <div className="row">
                            <div className="col-md-12">
                                <span>Envie um email para LOViZ</span>
                            </div>
                        </div>
                        <div className="row mt-10">
                            <div className="col-md-12">
                                <a href="mailto:contato@loviz.com.vc" className="c-primary"  target="_blank" rel="noreferrer">Email: contato@loviz.com.vc</a>
                            </div>
                        </div>
                    </CustomModal>
                    :
                    null
            }
            <section id="first-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="row cursor-pointer" onClick={() => setShowModal(!showModal)}>
                                <div className="col-md-12 d-flex justify-content-center">
                                    <img src={PhoneImg} width="100px" height="100px"/>
                                </div>
                                <div className="col-md-12 text-center mt-20">
                                    <span className="fs-18 c-gray font-italic fw-600">Ligue para a LOViZ</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mobile-mt-40">
                            <div className="row cursor-pointer" onClick={() => setShowModal1(!showModal1)}>
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <img src={EmailImg} width="100px" height="100px"/>
                                    </div>
                                    <div className="col-md-12 text-center mt-20">
                                        <span className="fs-18 c-gray font-italic fw-600">E-mail</span>
                                    </div>
                            </div>
                        </div>
                        <div className="col-md-3 mobile-mt-40">
                            <a href="https://api.whatsapp.com/send?phone=5508000800999"
                               target="_blank" rel="noreferrer">
                                <div className="row cursor-pointer">
                                    <div className="col-md-12 d-flex justify-content-center">
                                        <img src={WhatsAppImg} width="100px" height="100px"/>
                                    </div>
                                    <div className="col-md-12 text-center mt-20">
                                        <span className="fs-18 c-gray font-italic fw-600">Converse pelo WhatsApp</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FirstSection;