import React from "react";
import img_introduction from "../../../assets/img/Trabalhe Conosco/business-people-giving-high-five-at-desk.webp";
import "./style.css";

import HubspotForm from "react-hubspot-form";
import { useState } from "react";
import CustomModal from "../../../Parts/TrabalheConosco/Modal";

export default function Introduction() {
  const [showModal, setShowModal] = useState(false);
  return (
    <section id="introduction">
      {
        showModal
          ?
          <CustomModal title="" setShowModal={setShowModal}>
            <div className="style-modal">
              <iframe className="w-100" src="https://forms.monday.com/forms/embed/bef86390e35039d6a67104147c926a64?r=use1" width={650} height={500}></iframe>
            </div>
          </CustomModal>
          :
          null
      }
      <div className="container">
        <div className="row mobile-margin-5">
          <div className="col-md-12 border-radius-12">
            <h1 className="fs-45 c-primary ptb-20 text-center mobile-fs-25 mobile-text-center">
              Trabalhe com a gente
            </h1>
          </div>
        </div>
        <div className="row mt-80">
          {/* Item 2 */}
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={img_introduction}
                  alt="img"
                  className="border-radius-12 image-work-together"
                />
              </div>
            </div>
          </div>
          <div className="col-md-7 pl-50 mobile-padding-left">
            <div className="row">
              <div className="col-md-12">
                <h2 className="fs-25 c-primary mobile-fs-25 mobile-text-center mobile-mt-20">
                  Carreiras na LOViZ
                </h2>
              </div>
            </div>
            <div className="row ptb-20 mobile-ptb-10">
              <div className="col-md-9 col-lg-8">
                <p className="fs-15 fw-600 font-italic c-gray">
                  “Minha internet é muito rápida!” ou “Essa conexão telefônica é
                  boa demais” ...ninguém quase nunca diz coisas assim. A missão
                  do LOViZ é fornecer telefonia e internet abundante e confiável
                  em redes sempre rápidas e abertas. Em nossa equipe, você
                  trabalhará em um ambiente acelerado que desafia o status da
                  indústria de banda larga.
                </p>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 mobile-text-center">
                {/*<button className="fonts-button blue-button" onClick={() => setActive(!active)}>*/}
                {/*  Cadastre-se em uma vaga*/}
                {/*</button>*/}
                <button className="fonts-button blue-button" onClick={() => setShowModal(!showModal)}>
                  Cadastre-se em uma vaga
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<section className={active ? "form-hubspot activeModal" : "form-hubspot"} data-form>*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-md-8">*/}
      {/*        <div className="row">*/}
      {/*          <div className="col-md-12">*/}
      {/*            <div>*/}
      {/*              <HubspotForm*/}
      {/*                region="na1"*/}
      {/*                portalId="7177859"*/}
      {/*                formId="50d6e915-edcf-48f7-bc5c-bce134ab46ce"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="col-md-4">*/}
      {/*        <div>*/}
      {/*          <p className="blue-button c-white text-center" onClick={() => setActive(!active)}>Fechar</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
    </section>
  );
}
