import React from "react";

function BenefitItems({ sections }) {
  return sections.map((section, key) => (
    <section key={key} className="row align-items-center">
      <div
        className={`offset-md-1 col-md-4 ${
          key && key % 2 !== 0 ? "order-2" : ""
        }`}
      >
        {section["contents"].map((content, index) => (
          <div key={index}>
            <div className={`row ${index ? "mt-50" : ""}`}>
              <div className="col-md-12">
                <h3 className="fs-25 mb-0 font-italic fw-600">
                  {content.title}
                </h3>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-12">
                <span className="c-gray">{content.description}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-md-5 offset-md-1">
        <img src={section.img} alt={section.alt} />
      </div>
    </section>
  ));
}

export default BenefitItems;
