import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-W4DVSX7'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
);
