import React from "react";
import Banner from "../../Parts/LPEmpresas/Banner";
import Internet from "../../Parts/LPEmpresas/Internet";
import Loviz from "../../Parts/LPEmpresas/Loviz";
import Vantagens from "../../Parts/LPEmpresas/Vantagens";
import Plans from "../../Parts/LPEmpresas/Plans";
import Contato from "../../Parts/LPEmpresas/Contato";
import Faq from "../../Parts/LPEmpresas/Faq";
const LPEmpresas = () => {
  return (
    <section>
      <Banner />
      <Internet />
      <Loviz />
      <Vantagens />
      <Plans />
      <Contato />
      <Faq />
    </section>
  );
};

export default LPEmpresas;
