import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import './style.css'

function CustomModal({ children, title, setShowModal }) {
    return (
        <div className="custom-modal">
            <div className="container-fluid h-100">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-md-6">
                        <div className="modal-container">
                            <div className="row d-flex align-items-center">
                                <div className="col-10 col-sm-10 col-md-8">
                                    <h3 className="fs-25 fw-600">{title}</h3>
                                </div>
                                <div className="col-2 col-sm-2 col-md-4 text-right close-modal">
                                    <FontAwesomeIcon icon={faTimes} size="lg" className="c-primary cursor-pointer" onClick={() => setShowModal(false)} />
                                </div>
                            </div>
                            <div className="row mt-30">
                                <div className="col-md-12">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomModal;