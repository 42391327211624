import React from 'react';

import WifiImg from '../../../assets/img/Residence/icone.webp'

function Prepare() {
    return (
        <section id="prepare-your-internet">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 prepare-your-internet-col pr-70">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">Descomplique sua vida digital.</h2>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <p className="c-gray">Trabalhando em casa, assistindo filmes e séries via streaming, conectando todos seus dispositivos à rede…estamos cada dia mais conectados e dependentes de uma internet residencial estável e eficiente.</p>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12 d-flex justify-content-center">
                                <img src={WifiImg} alt="Wifi" width="130px"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 prepare-your-internet-col pl-70 mobile-mt-20">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="fs-25 mb-0 font-italic fw-600">Internet que conecta toda sua família.</h3>
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-12">
                                        <p className="c-gray">Obtenha largura de banda suficiente para fazer o que quiser, simultaneamente, com todo mundo conectado.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="fs-25 mb-0 font-italic fw-600">Internet em que você pode
                                            confiar.</h3>
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-12">
                                        <p className="c-gray">A internet de fibra óptica é construída para ser muito
                                            mais confiável do que as linhas de cabo tradicionais. Isso significa menos
                                            interrupções e menos preocupações.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="fs-25 mb-0 font-italic fw-600">Internet de novas
                                            possibilidades.</h3>
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-12">
                                        <p className="c-gray">Casas inteligentes, home office e trabalho híbrido, armazenamento em nuvem…são realidades que só antecipam novas tendências e tecnologias ainda a surgir. Sua residência está equipada para o futuro!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Prepare;