import React from "react";
import "./style.css";
import Terms from "../../Parts/Promocional/index.js";

function Promocional() {

    return (
        <div id="promotional-page">
            <Terms/>
        </div>
    );
}

export default Promocional;