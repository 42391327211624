import React from "react";
import "./style.css";
import Banner from "../../Components/Banner";
import BannerBackground from "../../assets/img/Socios/00-Imagem-Header.webp";
import Announcement from "../../Parts/Socio/Announcement";

export default function Socio() {
  return (
    <div id="socio-page">
      <Banner
        background={BannerBackground}
        backgroundOverlay="transparent, rgb(0, 0, 0, .5) 60%"
        classPrincipalTitleColor="c-white text-right"
        principalTitle="Sonhe (e cresça)</br>com a gente."
        classFontColor="c-white text-right"
        description="Seja nosso sócio."
        backgroundPosition="center"
      />
      <Announcement />
        <div className="container">
            <div className="col-md-12">
                <div className="mt-30 row justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center">
                                <iframe title="Contato"
                                        src="https://forms.monday.com/forms/embed/d5883ff98c8b7bd8847617d9dbdb6689?r=use1"
                                        width="700" height="680"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
