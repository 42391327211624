import React from 'react';

import './style.css';

import BannerImg from "../../../assets/img/empresas.webp"
import FormCep from '../../../Components/FormCep';

function Banner() {
    return (
        <section className="pt-0 background-style"
                  style={{ backgroundImage:
                      `linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${BannerImg})` }} id="banner-plans">
            <div className="container company-banner ptb-50-m">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <h1 className="c-white text-center">Seu negócio funciona quando sua internet funciona.</h1>
                            </div>
                        </div>
                        <div className="row mt-20 justify-content-center">
                            <div className="col-md-6 text-center">
                                <span className="c-white fs-14 fw-600 font-italic">Leve a LOViZ para a sua empresa.</span>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <FormCep sectionClass="p-0 company-form" hideText="true" parentColumnSize="12" responsiveFieldsColumn="col-12 col-sm-12 col-md-12 col-lg-10" responsiveButtonColumn="col-12 col-sm-12 col-md-12 col-lg-2 text-right" />
                            </div>
                        </div>
                    </div>`
                </div>
            </div>
        </section>
    );
}

export default Banner;