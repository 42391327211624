import React from 'react';

import FAQ from "../../Components/FAQ";
import VerticalTab from '../../Components/VerticalTab';

import Banner from '../../Parts/Contact/Banner'
import FirstSection from '../../Parts/Contact/FirstSection'

import Form from '../../assets/img/transferencia-de-titularidade.pdf'

import './style.css'

function Contact() {


    const faqList = [
        {
            title: "Geral",
            content: (
                <FAQ
                    questions={[
                        {
                            title: "Qual é o canal de atendimento da LOViZ?",
                            content:
                                "A LOViZ está presente em diversos canais, possibilitando assim uma melhor comunicação com seus clientes. Nosso atendimento é 24 horas por dia, 7 dias na semana, incluindo sábados, domingos e feriados<br>" +
                                "WhatsApp: <a href=\"https://api.whatsapp.com/send?phone=5508000800999&amp;text=Falar%20com%20WhatsApp%20\" title=\"Realizar atendimento online\" target=\"_blank\">0800 0800 999</a><br>" +
                                "Ligação Gratuita: <a href=\"https://api.whatsapp.com/send?phone=5508000800999&amp;text=Falar%20com%20WhatsApp%20\" title=\"Realizar atendimento online\" target=\"_blank\">0800 0800 999</a><br>" +
                                "Atendimento exclusivo empresarial ou Ligação direta: <a href='tel:554137977777' title='Ligar diretamente para LOViZ'>41 3797-7777</a><br>" +
                                "Facebook: <a href='https://www.facebook.com/loviz.com.vc' target='_blank'>facebook.com/loviz.com.vc</a><br>" +
                                "Portal do cliente <a href='https://portal.loviz.com.vc/'>portal.loviz.com.vc</a><br>" +
                                "Instagram: <a href=http://instagram.com/lovizcomvc target='_blank'>instagram.com/lovizcomvc</a>",

                        },
                        {
                            title: "Quero investir na LOViZ. É possível?",
                            content:
                                "Sim. A LOViZ está aberta a investimentos através do App do Investidor. " +
                                "Obtenha mais informações sobre como investir na LOViZ <a href='#'>aqui</a>.",
                        },
                        {
                            title: "Como faço a mudança de titularidade?",
                            content:
                                "Para realizar a mudança de titularidade, basta preencher e nos enviar o " +
                                "formulário de mudança de titularidade. Clique " +
                                `<a href='${Form}' target='_blank'>aqui</a> ` +
                                "para acessar o documento.",
                        },
                        {
                            title: "Como alterar meus dados cadastrados na LOViZ",
                            content:
                                "Para atualizar seus dados junto a LOViZ, ligue " +
                                "ou nos envie um WhatsApp para o número <a href='https://api.whatsapp.com/send?" +
                                "phone=5508000800999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.",
                        },
                    ]}
                />
            ),
        },
        {
            title: "LOViZ Fibra",
            content: (
                <FAQ
                    questions={[
                        {
                            title: "O que é fibra óptica?",
                            content:
                                "A fibra óptica é uma tecnologia de transmissão de " +
                                "dados por meio da luz. Ela não sofre nenhuma interferência, " +
                                "como de campo magnético ou eletricidade, entre outros, por " +
                                "isso sua qualidade é muito superior.",
                        },
                        {
                            title: "Quais as vantagens da Internet fibra da LOViZ?",
                            content:
                                "A fibra da LOViZ chega dentro da casa do cliente, " +
                                "permitindo que o ciclo de transmissão óptica fique " +
                                "completa, desde a rede central até o modem do assinante. " +
                                "Assim, os benefícios da Fibra são garantidos: alta capacidade" +
                                " de transmissão e qualidade do sinal são mantidas até a sua casa." +
                                " Além disso com a fibra LOViZ você terá mais performance, menos " +
                                "interferência, mais estabilidade e melhor experiência para acessar " +
                                "sites, ver vídeos, jogar online e muito mais, além do modem Wi-Fi" +
                                " Extreme que proporciona a melhor experiência ao navegar pelo Wi-Fi.",
                        },
                        {
                            title: "É possivel levar a LOViZ Fibra para o meu novo endereço?",
                            content:
                                "Sim, desde que tenhamos viabilidade técnica em seu novo endereço. " +
                                "Entre em contato pelo telefone ou nos envie um " +
                                "WhatsApp para o número <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a> " +
                                "para que possamos verificar a viabilidade de seu endereço.\n",
                        },
                        {
                            title: "Em quais aparelhos posso utilizar minha internet e conexão LOViZ?",
                            content:
                                "Pode utilizar sua internet LOViZ em sua Smart TV, celulares," +
                                " tablet, notebook, consoles de jogos, dentre outros. " +
                                "A velocidade da internet depende da capacidade dos seus" +
                                " aparelhos e da distância entre esses dispositivos e " +
                                "o modem. Lembre-se que a velocidade é dividida entre todos" +
                                " os outros dispositivos conectados à sua rede, seja via cabo ou Wi-Fi.",
                        }, {
                            title: "Qualquer pessoa ou empresa pode contratar a internet da LOViZ?",
                            content:
                                "Sim. A LOViZ oferece planos de internet ultra banda larga para pessoas " +
                                "físicas e jurídicas. Consulte a disponibilidade através do telefone " +
                                "ou WhatsApp <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.\n",
                        }, {
                            title: "Qual a vantagem da internet LOViZ em comparação aos serviços disponíveis no mercado?",
                            content: "A LOViZ oferece acesso em banda extra larga por meio de cabos de fibra óptica " +
                                "(uma das melhores tecnologias de tráfego de dados, voz e vídeo)." +
                                " Oferecemos produtos e serviços altamente especializados a um preço justo e competitivo."
                        },
                        {
                            title: "O contrato da LOViZ possui fidelidade?",
                            content: "Nosso serviço pode ser adquirido com ou sem fidelidade." +
                                "Em caso da escolha da contratação com fidelidade de 12 meses, se ocorrer" +
                                " cancelamento do serviço antes desse período, implicará no pagamento de " +
                                "valor compensatório, conforme contrato." +
                                "O valor a ser cobrado é proporcional ao tempo que falta para acabar a fidelidade.\n"
                        }, {
                            title: "Como faço para alterar minha velocidade?",
                            content: "É muito simples. Entre em contato pelo WhatsApp <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>." +
                                " que nossos consultores comerciais verificarão os" +
                                " planos e pacotes disponíveis para sua região.\n"
                        }, {
                            title: "Minha fibra óptica foi rompida. O que devo fazer?",
                            content: "Calma! Existem alguns cuidados e procedimentos que você deve tomar" +
                                " quando a sua fibra óptica é rompida. Siga os passos abaixo:<br/>" +
                                "1) Mantenha o equipamento ligado e não mexa no local do rompimento.<br/>" +
                                "2) Entre em contato conosco via WhatsApp ou telefone através do número " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.e solicite um chamado técnico junto a nossa equipe de " +
                                "suporte técnico especializado, explicando o rompimento.\n"
                        }, {
                            title: "Posso usar meu próprio roteador com a internet LOViZ?",
                            content: "Sim! Se você já possui um roteador, pode interligá-lo ao" +
                                " modem da LOViZ e utilizar o sinal de Wi-Fi deste equipamento.\n" +
                                "Lembrando que não prestamos suporte a equipamentos particulares" +
                                " e nem garantimos a qualidade de seu sinal.\n" +
                                "Para maiores informações ligue ou mande um WhatsApp para " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>"
                        }, {
                            title: "A LOViZ tem atendimento 24 horas?",
                            content: "Sim. A equipe LOViZ atende pelo WhatsApp/Telefone " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>, " +
                                "7 dias da semana, 24 horas por dia, incluindo sábados, domingos e feriados.\n"
                        }, {
                            title: "Posso usar meu própio modem?",
                            content: "Não. Apenas o modem da LOViZ pode ser " +
                                "utilizado. Você pode utilizar outro equipamento," +
                                " roteador, para auxiliar na distribuição do Wi-Fi."
                        }, {
                            title: "O que é ONT/ONU?",
                            content: "É um equipamento chamado Optical Network Terminal (ONT)" +
                                " disponibilizado em comodato, na residência ou empresa do " +
                                "cliente para a instalação da internet banda larga da LOViZ. " +
                                "É o que normalmente se chama de modem."
                        }, {
                            title: "Qual é o prazo de instalação?",
                            content: "Durante o atendimento junto ao comercial, " +
                                "serão dadas sugestões de data de instalação para o cliente.\n"
                        }, {
                            title: "A velocidade da internet LOViZ é garantida?",
                            content: "Por lei, todas as operadoras devem seguir os artigos " +
                                "\"<a href='https://informacoes.anatel.gov.br/legislacao/resolucoes/" +
                                "2011/57-resolucao-574#art16'>16 a 18 da Resolução nº 574/2011 da Anatel\" </a>" +
                                "Porém a LOViZ trabalha para entregar uma conexão e experiência muito" +
                                " superior ao que a lei exige.\n"
                        }, {
                            title: "Preciso contratar telefone fixo para ter a internet Ultra Banda Larga da LOViZ?",
                            content: "Não, a ativação do serviço de telefonia fixa é opcional, não obrigatória.\n"
                        }, {
                            title: "A ultra banda larga da LOViZ possui IP fixo?",
                            content: "Depende do plano escolhido, para consultar todos os planos" +
                                " entre em contato com nosso time comercial, através do WhatsApp " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>\n"
                        }, {
                            title: "Existe algum limite mensal de Download e Upload com a ultra banda larga da LOViZ?",
                            content: "Não, com a ultra banda larga da LOViZ, você poderá " +
                                "navegar sem limites e não pagará nada a mais por isso.\n"
                        },
                    ]}
                />
            ),
        },
        {
            title: "Wi-Fi",
            content: (
                <FAQ
                    questions={[
                        {
                            title: "O que é Wi-Fi Extreme?",
                            content: "O Wi-Fi Extreme é entregue pelo nosso modem " +
                                "de última geração (tecnologia Wi-Fi AC), que proporciona uma" +
                                " melhor conexão e experiência. Graças a essa tecnologia, você poderá " +
                                "navegar em ultra velocidades, através do Wi-Fi, utilizando seus" +
                                " aparelhos que também possuam a tecnologia Wi-Fi AC.\n"
                        },
                        {
                            title: "Qual a difrença entre a rede 2,4 GHz e 5 GHz (Wi-Fi AC)?",
                            content: "A rede 2.4GHz tem maior alcance, porém sofre mais interferência " +
                                "e possui velocidades máximas de 50Mbps dependendo do desempenho do seu" +
                                " dispositivo e e cenário que se encontra. Já a rede 5GHz (Wi-Fi AC) tem" +
                                " menor alcance, porém suporta velocidades mais altas quando o dispositivo estiver mais próximo " +
                                "ao modem Wi-Fi, onde sua experiência de navegação e performance é melhor.\n"
                        },
                        {
                            title: "Como aumentar a cobertura da minha rede Wi-Fi?",
                            content: "Caso sua casa seja grande ou você queira ampliar o " +
                                "sinal do seu Wi-Fi, recomendamos a instalação de repetidores " +
                                "Wi-Fi com tecnologia MESH. Para mais informações clique aqui.\n"
                        },
                        {
                            title: "Minha conexão Wi-Fi não está boa. O que pode estar acontecendo?",
                            content: "Verifique a existência de barreiras físicas, pois elas " +
                                "podem interferir na sua conexão. Lâmpadas, geladeiras, caixas de som," +
                                " paredes, portas, vidros, espelhos são exemplos de barreiras físicas." +
                                " Além das barreiras físicas, a distância entre seu dispositivo e o " +
                                "modem Wi-Fi podem interferir na qualidade do sinal e consequentemente " +
                                "na velocidade de acesso, se sua casa for grande, " +
                                "será necessário instalar repetidores Wi-Fi com tecnologia MESH, para mais informações clique aqui.\n"
                        },
                        {
                            title: "Em quais aparelhos posso utilizar minha conexão Wi-Fi Fibra?",
                            content: "Na sua smart TV, celulares, tablet, notebook, consoles de " +
                                "jogos, dentre outros. Por isso, a velocidade da internet depende" +
                                " da capacidade dos seus aparelhos e também da distância entre esses " +
                                "dispositivos e o modem LOViZ. Lembrando que a velocidade é dividida entre todos os outros" +
                                " dispositivos conectados (smart TV, celular, tablet etc.) à sua rede Wi-Fi.\n"
                        },
                        {
                            title: "Como fazer a troca de senha Wi-Fi?",
                            content: "Você pode estar solicitando a troca " +
                                "da senha Wi-Fi através de nosso telefone ou WhatsApp <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.\n"
                        },
                    ]}
                />
            ),
        },
        {
            title: "Telefonia Fixa",
            content: (
                <FAQ
                    questions={[
                        {
                            title: "Como fazer ligações de longa distância do meu telefone digital LOViZ?",
                            content: "Ligações de Longa Distância (ligações interurbanas) são " +
                                "ligações realizadas para localidades com o código de área (DDD) " +
                                "diferente do código de área da cidade onde você está. Para ligar, " +
                                "digite: 0 + DDD + número do telefone, exemplo: 04137977777\n"
                        },
                        {
                            title: "Posso alterar o número do meu telefone?",
                            content: "Sim, basta solicitar para nossa equipe, ligando ou enviando um WhatsApp para " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>"
                        },
                        {
                            title: "Meu telefone não está recebendo chamadas somente de alguns números, é normal?",
                            content: "Não é normal, se isso estiver ocorrendo, entre em contato" +
                                " com nosso suporte através do telefone ou WhatsApp <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>, informando o problema e o" +
                                " número de origem que não está conseguindo completar a chamada.\n"
                        },
                    ]}
                />
            ),
        },
        {
            title: "Teste de velocidade",
            content: (
                <FAQ
                    questions={[
                        {
                            title: "O que é o teste de velocidade da LOViZ?",
                            content: "O teste de velocidade é um serviço online oferecido pela LOViZ com base no velocímetro Ookla.<br/>" +
                                "O objetivo é detectar com precisão a velocidade de transferência de dados, informando se " +
                                "a banda atual está de acordo com a velocidade contratada. Clique " +
                                "<a href='https://lovizcomvc.speedtestcustom.com/' target='_blank'>aqui</a> para medir a velocidade " +
                                "da sua internet.\n"
                        },
                        {
                            title: "A velocidade do Wi-Fi é a mesma velocidade da via cabo?",
                            content: "Não. Muitos fatores podem interferir na velocidade de transmissão do Wi-Fi, como a quantidade de dispositivos conectados e a distância entre esses dispositivos e o modem.<br/>\n" +
                                "Por isso, a LOViZ recomenda que, quando possível, os dispositivos sejam conectados por cabo (como smart TVs, computadores desktop e consoles de videogame).<br/>\n" +
                                "No cabo, a velocidade total da internet estará sempre disponível e é muito menos suscetível a interferências externas. Algumas condições do ambiente podem reduzir drasticamente o desempenho da rede sem fio (Wi-Fi), tais como:<br/>" +
                                "- Paredes;<br/>" +
                                "- Bluetooth de aparelhos portáteis como fones de ouvido, teclados e mouse sem fio, etc;<br/>\n" +
                                "- Outras redes sem fio (vizinhos, repetidores, outros roteadores etc);<br/>\n" +
                                "- Dispositivos que trabalhem no mesmo espectro sem fio de 2.4GHz e 5Ghz como telefones sem fio;<br/>\n" +
                                "- Espelhos;\n"
                        },
                        {
                            title: "Por que meu dispositivo não atinge a velocidade contratada?",
                            content: "A limitação em se atingir velocidades mais altas em um único" +
                                " dispositivo, poderá estar relacionada aos seguintes fatores: " +
                                "limitação do próprio dispositivo do cliente (quantidade de memória, " +
                                "processador, modelo, tipo de conexão entre outras especificações), bem " +
                                "como o distanciamento entre o dispositivo e o modem Wi-Fi (quanto mais" +
                                " próximo ao modem, mais forte será o sinal do Wi-Fi), além da infraestrutura" +
                                " do local, interferências do meio, o site que está sendo consultado e outros " +
                                "programas executados em segundo plano no seu dispositivo.\n"
                        },
                        {
                            title: "Como funciona a rede sem fio/sinal do Wi-Fi?",
                            content: "A rede sem fio ou wireless traz a comodidade de acessar" +
                                " a internet em qualquer lugar dentro do raio de alcance do " +
                                "roteador sem fio, porém, existe um limite para esse alcance." +
                                " O roteador sem fio usa ondas de rádio para espalhar no ambiente" +
                                " o sinal da internet, mas esse tipo de comunicação é suscetível a " +
                                "interferências eletromagnéticas, que podem ocasionar quedas de velocidade," +
                                " instabilidade e até perda de sinal.\n"
                        },
                        {
                            title: "Como devo realizar o teste de velocidade do meu link de Internet?",
                            content: "O teste de velocidade é feito usando o seu computador ou até mesmo o celular. O mais indicado é usar um dispositivo conectado diretamente ao roteador, por meio de um cabo de rede, ou seja, utilizando um computador ou notebook.<br/>\n" +
                                "Antes de iniciar o teste, siga estas dicas para obter o resultado mais preciso possível:<br/>\n" +
                                "- Desconecte todos os dispositivos (celulares, tablets e outros aparelhos), mantendo conectado em seu modem apenas o equipamento a qual será utilizado no teste;<br/>\n" +
                                "- Confira que não existem outras pessoas no local usando a sua internet;<br/>\n" +
                                "- Feche todos os programas como Spotify, torrents, downloads, Netflix, etc e interrompa as atualizações, para garantir que não influencie no resultado;<br/>\n" +
                                "- Tenha certeza de que o site de teste que você está utilizando é o oficial da LOViZ e é o único site aberto;<br/>\n" +
                                "- Sempre que possível utilize um computador ou notebook conectado ao modem através de um cabo de rede CAT5E ou superior.<br/>\n" +
                                "- Certifique-se de que o dispositivo utilizado no teste atende os requisitos mínimos para a ultra banda larga contratada. Equipamentos mais antigos ou simples, não possuem capacidade e/ou velocidade de hardware suficiente para altas velocidades.<br/>\n" +
                                "Seguindo nossas dicas acima, sua conexão não sofrerá nenhum tipo de desvio, nem estará saturada no momento de realizar o teste. Outra dica muito importante é realizar a medição várias vezes por dia, em horários alternados.<br/>\n" +
                                "Clique <a href='https://lovizcomvc.speedtestcustom.com/' target='_blank'>aqui</a> para medir a velocidade da sua internet.\n"
                        },
                        {
                            title: "Durante a realização do teste de velocidade, o desempenho da internet pode ser afetado?",
                            content: "Sim. Por isso, para obter resultados mais precisos, o recomendado " +
                                "é não usar a conexão Wi-Fi e estar com um computador isolado em sua rede e conectado por cabo " +
                                "enquanto o teste está em execução. Clique <a href='https://lovizcomvc.speedtestcustom.com/' " +
                                "target='_blank'>aqui</a> para medir a velocidade da sua internet.\n"
                        },
                        {
                            title: "A velocidade da internet LOViZ é garantida?",
                            content: "Por lei, todas as operadoras devem seguir os artigos \<a href='https://www.anatel.gov.br/legislacao/resolucoes/2011/57-resolucao-574#art16'>\"16 a 18 da Resolução nº 574/2011 da Anatel\"</a>. " +
                                "Porém a LOViZ trabalha para entregar uma conexão e experiência muito superior ao que a lei exige.\n"
                        },
                        {
                            title: "O que é velocidade de download e upload?",
                            content: "A velocidade de download indica o quão rápido você pode receber dados de um" +
                                " servidor para o seu dispositivo, ou seja, é a sua navegação para carregar páginas " +
                                "online, ver filmes em serviços de streaming e baixar arquivos. Essa velocidade é o que" +
                                " normalmente compramos em nosso plano, considerada a mais importante.<br/>\n" +
                                "A velocidade de upload é o contrário da anterior, ou seja, é o quão rápido " +
                                "conseguimos enviar dados para outro destinatário - como colocar um vídeo no YouTube," +
                                " um arquivo no google drive ou uma foto no Instagram, por exemplo. Normalmente, usamos" +
                                " muito mais serviços de download do que upload, por isso, essa velocidade tende a ser" +
                                " menor do que a anterior."
                        },
                    ]}
                />
            ),
        },
        {
            title: "Suporte Técnico",
            content: (
                <FAQ
                    questions={[
                        {
                            title: "Minha internet está lenta, o que devo fazer?",
                            content: "Tente reiniciar o modem, desligando ele da tomada e ligando novamente.<br/>\n" +
                                "Caso precise de ajuda ligue ou mande um WhatsApp para <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>"
                        },
                        {
                            title: "Não consigo acessar alguns sites. O que devo fazer?",
                            content: "Tente reiniciar o modem, desligando ele da tomada e ligando novamente.<br/>\n" +
                                "Recomendamos também reiniciar o dispositivo que você está usando (celular, computador, etc.).<br/>\n" +
                                "Confira se outras páginas da internet também não estão carregando, pode ser que o site que você está tentando acessar esteja temporariamente fora do ar.<br/>\n" +
                                "Caso precise de ajuda ligue ou mande um WhatsApp para <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>."
                        },
                        {
                            title: "Minha internet não está funcionando e tem luz vermelha no modem, o que devo fazer?",
                            content: "No modem, a luz vermelha, significa que a fibra não está conectada ou está" +
                                " rompida. Caso seu modem apresente essa luz vermelha, verifique se todos os cabos" +
                                " estão conectados corretamente, caso estejam conectados com a luz vermelha piscando, " +
                                "entre em contato com nosso suporte, através do telefone ou WhatsApp " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.\n"
                        },
                        {
                            title: "Em quais casos devo abrir um chamado técnico e como devo fazer isso?",
                            content: "Você pode abrir um chamado técnico quando: - Não consegue acessar nenhum serviço na Internet;<br/>\n" +
                                "- Alguns sites demoram para carregar;<br/>\n" +
                                "- Páginas são carregadas pela metade;<br/>\n" +
                                "- Vídeos travam constantemente;<br/>\n" +
                                "- Vai fazer um download ou upload de arquivos e há uma grande demora para concluir;<br/>\n" +
                                "- A internet cai com frequência;<br/>\n" +
                                "- A velocidade da internet fica variando;<br/>\n" +
                                "- Não consegue acessar a Internet utilizando cabo ou Wi-Fi.<br/>\n" +
                                "Para entrar em contato com a equipe de suporte ligue ou mande um WhatsApp para " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.<br/>\n" +
                                "Em muitos casos o processo de desligar o modem por dois minutos e depois ligar ele novamente, já resolve o problema de conexão sem a necessidade de entrar em contato com o suporte técnico.\n"
                        },
                        {
                            title: "Em caso de problemas, qual o tempo de resposta da LOViZ?",
                            content: "O tempo de resposta dependerá do tipo de solicitação desejada. Mas não se preocupe, " +
                                "temos uma equipe especializada que lhe dará todo o respaldo necessário em tempo hábil.\n"
                        },
                        {
                            title: "Como funciona a mudança de endereço?",
                            content: "Para fazer a solicitação de mudança de endereço da internet da LOViZ, você precisa, " +
                                "primeiramente, entrar em contato com a nossa central de atendimento pelo número ou WhatsApp " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.<br/>\n" +
                                "Em seguida vamos verificar se existe viabilidade no novo endereço. " +
                                "Se existir, a mudança de endereço poderá ser feita. Se não, não será possível realizar o procedimento.\n"
                        },
                        {
                            title: "Quero mudar de endereço, mas não existe cobertura da LOViZ no novo endereço, se eu cancelar, precisarei pagar a multa de fidelidade?",
                            content: "Sim, caso o contrato seja cancelado antes de completar um ano, " +
                                "será cobrada a multa contratual pró-rata (proporcional ao tempo que " +
                                "falta para a fidelização acabar).<br/>\n" +
                                "Fundamentação Legal: <a href='https://www.anatel.gov.br/legislacao/" +
                                "resolucoes/2013/465-resolucao-614#art39' target='_blank'>\"Art. " +
                                "39 da Resolução nº 614/2013 da Anatel\"</a>.\n"
                        },
                        {
                            title: "Existe uma taxa para mudança de endereço?",
                            content: "Sim. Para maiores informações entre em contato com nosso Suporte, através do WhatsApp <a href='https://api.whatsapp.com/send?phone=550800080" +
                            "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>. ou por outro canal oficial de atendimento.\n"
                        },
                    ]}
                />
            ),
        },
        {
            title: "Fatura",
            content: (
                <FAQ
                    questions={[
                        {
                            title: "Como tirar a 2ª via da minha fatura?",
                            content: "Você poderá tirar a segunda via através do portal do cliente, " +
                                "acesse <a href='https://portal.loviz.com.vc/'>minhaloviz.com.vc</a><br/>" +
                                "Se preferir, envie um WhatsApp com a palavra “boleto” para " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>"
                        },
                        {
                            title: "Como faço para obter login e senha para acessar o portal do cliente minhaloviz.com.vc",
                            content: "Os dados de acesso ao portal são enviados para o e-mail cadastrado no ato " +
                                "da ativação dos serviços. Recomenda-se trocar esta senha após o seu recebimento.<br/>\n" +
                                "Caso você tenha esquecido sua senha, no portal " +
                                "<a href='https://portal.loviz.com.vc/'>minhaloviz.com.vc</a>, clique em \"Esqueci Minha Senha\".<br/>\n" +
                                "Caso precise de ajuda, ligue ou chame-nos no WhatsApp " +
                                "<a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>."
                        },
                        {
                            title: "Como saber se estou inadimplente com o pagamento da fatura?",
                            content: "Para consultar suas faturas em aberto, acesse <a href='" +
                                "https://portal.loviz.com.vc/'>minhaloviz.com.vc</a>\n" +
                                "Se preferir fazer essa consulta através de nossa central de atendimento," +
                                " ligue ou envie um WhatsApp para <a href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>.\""
                        },
                        {
                            title: "O que acontece se eu não pagar a minha fatura?",
                            content: "Evite que seu serviço seja suspenso, fique com sua" +
                                " fatura em dia, acesse <a href='" +
                                "https://portal.loviz.com.vc/'>minhaloviz.com.vc</a>" +
                                " e retire sua segunda via agora.\n" +
                                "Se preferir ligue ou chame-nos no WhatsApp <a " +
                                "href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>."
                        },
                        {
                            title: "Paguei o boleto, mas continuo inadimplente. Por quê?",
                            content: "A quitação do débito através de boleto bancário não" +
                                " acontece automaticamente. Depois que você efetua o pagamento, " +
                                "o banco leva até 48 horas úteis para informar à LOViZ que a fatura foi paga.<br/>\n" +
                                "“E se o meu pagamento continuar pendente mesmo após esse período?”<br/>\n" +
                                "Se mesmo depois das 48 horas úteis o seu pagamento permanecer como pendente" +
                                " e a fatura ainda estiver em aberto, você pode encaminhar o comprovante através " +
                                "do WhatsApp para o número <a " +
                                "href='https://api.whatsapp.com/send?phone=550800080" +
                                "0999&text=Falar%20com%20WhatsApp%20' target='_blank'>0800 0800 999</a>. " +
                                "Caso prefira, você também poderá ligar" +
                                " gratuitamente para o número <a href='tel:08000800999'>0800 0800 999</a>."
                        },
                        {
                            title: "Quanto tempo demora para minha internet voltar a funcionar após a baixa do pagamento?",
                            content: "Os serviços são restabelecidos assim que o pagamento é " +
                                "informado pelo banco. Lembrando que o pagamento através de boleto " +
                                "pode levar até 48 horas úteis para que o banco informe o pagamento a LOViZ.\n"
                        },
                        {
                            title: "Meus serviços foram instalados recentemente. Por que a primeira conta veio com cobrança diferente do valor que contratei?",
                            content: "No primeiro mês, você será cobrado apenas a partir do dia da ativação " +
                                "do serviço, e não pelo ciclo completo de 30 dias. Ou seja, você será cobrado " +
                                "proporcionalmente ao período utilizado.\n"
                        },

                    ]}
                />
            ),
        },
    ];

    return (
        <div id="contact-page">
            <Banner/>
            <FirstSection/>
            <section id="contact-faq">
                <div className="container">
                    <VerticalTab title="FAQs" tabList={faqList}/>
                </div>
            </section>
        </div>
    );
}

export default Contact;