import React from "react";
import { useState } from "react";

import "./style.css";


export default function Form({ modal, setModal }) {
  const [name, setName] = useState();
  const [country, setCountry] = useState();
  const [ddd, setDDD] = useState();
  const [number1, setNumber1] = useState();
  const [number2, setNumber2] = useState();

  function SubmitButton(){
    const numberArray = [country, ddd, number1, number2];
    

    const number = numberArray.reduce((acc, item) => {
      acc += item;
      return acc;
    }, "");

    setModal(false);
  }

  return (
    <section className="form-cta">
      <div className="container bg-white ptb-20">
        <div className="row mb-20">
          <div className="fs-20 text-end close-modal" onClick={()=>setModal(false)}>X</div>
          <div className="col-md-12">
            <h2 className="fs-20 text-center title-cta">
              Solicitar mais informações
            </h2>
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <div className="col-md-12">
            <div className="row justify-content-center text-center">
              <div className="col-md-12 form-name d-flex">
                <input
                  type="text"
                  placeholder="Nome*"
                  className="c-dark fw-600"
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Sobrenome*"
                  className="c-dark fw-600"
                />
              </div>
              <div className="col-md-12 form-email mt-10 mb-10 mb-20">
                <input
                  type="text"
                  placeholder="E-mail"
                  className="c-dark fw-600"
                />
              </div>
              <div className="col-md-12">
                <div className="row mb-20">
                  <div className="col-md-12 form-button d-flex justify-content-center">
                    <a href="#" className="c-white fs-15">
                      Celular
                    </a>
                    <a href="#" className="c-dark fs-15">
                      Fixo
                    </a>
                    <a href="#" className="c-dark fs-15">
                      Rádio
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex form-cpf">
                <input
                  type="text"
                  placeholder="XX"
                  onChange={({ target }) => {
                    setCountry(target.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="9"
                  onChange={({ target }) => {
                    setDDD(target.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="XXXX"
                  onChange={({ target }) => {
                    setNumber1(target.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="XXXX"
                  onChange={({ target }) => {
                    setNumber2(target.value);
                  }}
                />
              </div>
              <div className="col-md-12 d-flex justify-content-center mt-20 form-button-person">
                <a href="#" className="c-dark fs-15">
                  Para mim
                </a>
                <a href="#" className="c-dark fs-15">
                  Para outras pessoas
                </a>
              </div>
            </div>
            <button
              className="button-cta fw-600 c-white"
              onClick={SubmitButton}
            >
              Comece agora
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
