import React from "react";

export default function Contato() {
  return (
    <section id="contato" className="strip">
      <div className="container d-flex flex-column justify-content-center align-items-center gap-3">
        <h1 className="c-white fs-24 font-itallic ">Vem ser LOVıZ!</h1>
        <h2 className="fs-28 c-white font-italic fw-600 mobile-fs-20 text-center">
          Preços promocionais por tempo limitado! <br/> Faça um teste (e cancele se
          desejar)!
        </h2>
        <a
          className="btn btn-lg btn-primary c-white"
          href="https://api.whatsapp.com/send?phone=558000800999&text=Planos%20Residenciais"
        >
          Entrar em contato{" "}
        </a>
      </div>
    </section>
  );
}
