import React from "react";
import V1 from "../../assets/LP/v1.png";
import V2 from "../../assets/LP/v2.png";
import V3 from "../../assets/LP/v3.png";
import V4 from "../../assets/LP/v4.png";
import V5 from "../../assets/LP/v5.png";
import V6 from "../../assets/LP/v6.png";
import V7 from "../../assets/LP/v7.png";
import V8 from "../../assets/LP/v8.png";
import V9 from "../../assets/LP/v9.png";


export default function Vantagens() {
  const vantagensArray = [
    {
      title: "Internet Ultra Rápida",
      description: "Velocidades surpreendentes de 600 e 1000 Megabits",
      icon: V1,
    },
    {
      title: "Super Wi-Fi Onboard",
      description:
        "Modem ultra-moderno Super Wi-Fi da LOVıZ entregue em comodato mediante solicitação",
      icon: V2,
    },
    {
      title: "Sem Taxa de Instalação",
      description:
        "Instalação profissional e gratuita de um técnico especializado da LOVıZ",
      icon: V3,
    },
    {
      title: "Planos Flexíveis",
      description:
        "Faça upgrades ou cancele a qualquer momento. Você pode até usar seu próprio roteador, se desejar",
      icon: V4,
    },
    {
      title: "Streaming LOVıZ VıDEO",
      description:
        "Assista séries, filmes, esportes e desenhos quando quiser. Com a LOVıZ VıDEO, você tem conteúdo do seu jeito e diversão para toda a família",
      icon: V5,
    },
    {
      title: "Tecnologia FHS (R)",
      description:
        "Fiber Hyper Speed - tecnologia proprietária da LOVıZ de internet de altíssima velocidade, baixa latência e alta disponibilidade",
      icon: V6,
    },
    {
      title: "Telefone Infinity Ilimitado",
      description:
        "Ligações para celular e fixo de qualquer operadora do Brasil usando o código 14 ou 31",
      icon: V7,
    },
    {
      title: "Suporte ao Cliente 24h",
      description:
        "Conte com nossos especialistas 24h para te ajudar com problemas de tecnologia, tirar dúvidas e realizar diagnósticos sempre que precisar",
      icon: V8,
    },
    {
      title: "Digital /Audio Books by Skeelo",
      description:
        "Acesso a uma infinidade de livros digitais para ler ou ouvir em qualquer lugar e quando quiser",
      icon: V9,
    },
  ];

  return (
    <section id="vantagens" className="bg-gray">
      <div className="container">
        <div className="grid-icons">
          {vantagensArray.map((vantagem) => (
            <div key={vantagem.title}>
              <img src={vantagem.icon} alt="" />
              <h2 className="fs-20 c-dark font-italic fw-600 mobile-fs-20">
                {vantagem.title}
              </h2>
              <span className="c-gray font-italic fs-18 wt-435 mt-10"> {vantagem.description} </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
