import React from 'react';

function Banner() {
    return (
    <section id="banner">
        <div className="container contact-banner ptb-50-m">
            <div className="row mt-20 mb-20 justify-content-center">
                <div className="col-md-8 pb-40 mobile-pb-16 col-md-8 text-justify d-flex flex-column gap-3">
                    <div className="col-md-12">
                        <h1 className="c-white text-center">Precisa de ajuda? Estamos do seu lado.</h1>
                    </div>
                    <div className="col-md-12 text-center">
                        <span className="c-white fs-14 fw-600 font-italic">Conte com a gente para solucionar uma dúvida, resolver um problema, fazer uma solicitação ou alteração. Estamos 24h do seu lado.</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Banner;