import React from "react";
import { Link } from "react-router-dom";

function StartNow() {
  return (
    <section id="start-now">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title text-center">Comece agora</h2>
          </div>
        </div>
        <div className="row mt-50">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 text-center">
                <a
                  href="#first-phone-section"
                  className="blue-button c-white"
                  onClick={() =>
                    window.dataLayer.push({ event: "Checar Disponibilidade" })
                  }
                >
                  Checar disponibilidade
                </a>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12 text-center">
                <span className="c-gray">
                  Têm perguntas? Nossa equipe de atendimento pode te ajudar.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-50">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12 text-center">
                <Link to="fale-conosco" className="c-primary fw-600">
                  Fale conosco
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StartNow;
