import React, {Component} from "react";
import axios from 'axios';

class NameForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: []
        }
    }

    componentDidMount() {

        axios.get('http://api.multicanal.smartisp.com.br:10002/?p=04086108674')
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <div className="bg-gray">
                <br/>
            </div>
        )
    }
}

export default NameForm;