import React from 'react';

import WifiImg from '../../../assets/img/Company/wifi.webp'

function Prepare() {
    return (
        <section id="prepare-your-internet">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-5 prepare-your-internet-col pr-70">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="section-title">Prepare sua internet para o que vem por aí.</h2>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <p className="c-gray">Pedidos online. Sistemas de ponto de venda sem fio. Novas
                                    tecnologias que mantêm seus negócios funcionando. Sua internet está pronta para o
                                    que você precisa quando você tem LOViZ.</p>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12 d-flex justify-content-center">
                                <img src={WifiImg} alt="Wifi" width="130px"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 prepare-your-internet-col pl-70 mobile-mt-20">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="fs-25 mb-0 font-italic fw-600">Internet que conecta todo
                                            mundo.</h3>
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-12">
                                        <p className="c-gray">Obtenha largura de banda suficiente para fazer o que
                                            quiser. E com uploads tão rápidos quanto downloads, você poderá ser ainda
                                            mais eficiente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="fs-25 mb-0 font-italic fw-600">Internet em que você pode
                                            confiar.</h3>
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-12">
                                        <p className="c-gray">A internet de fibra óptica é construída para ser muito
                                            mais confiável do que as linhas de cabo tradicionais. Isso significa menos
                                            interrupções e menos preocupações.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-30">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3 className="fs-25 mb-0 font-italic fw-600">Internet de novas
                                            possibilidades.</h3>
                                    </div>
                                </div>
                                <div className="row mt-10">
                                    <div className="col-md-12">
                                        <p className="c-gray">Videoconferências em HD? Enviando arquivos grandes para a
                                            nuvem? Um representante de negócios da LOViZ pode ajudá-lo a encontrar o que
                                            é certo para você hoje e amanhã.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Prepare;