import React from "react";
import {Link} from "react-router-dom";
import "./style.css";
import HouseVideo from "../../../assets/img/GigabitInternet/house-video.mp4";
import Video from "../../../assets/img/GigabitInternet/house_video.mp4";


export default function TypeInternet() {
    return (
        <section id="type-internet">
            <div className="container">
                <div className="row">
                    {/* Section's title */}
                    <div className="col-md-12 pb-40">
                        <h1 className="fs-45 c-dark text-center mobile-fs-35">
                            Um tipo <span className="c-primary">diferente</span> de Internet
                        </h1>
                    </div>
                    {/* Items */}
                </div>
                <div className="row justify-content-around" id="item-1">
                    <div className="col-md-6 item-1">
                        {/*<img src={HouseSvg} alt="" />*/}
                        <video
                            src={Video}
                            alt="House"
                            autoPlay={true}
                            loop={true}
                            type="video/mp4"
                            className="border-radius-12 car-running"
                        />
                    </div>


                    <div className="col-md-6 item-2">
                        <div className="row ml-10">
                            <div className="col-md-12">
                                <div className="row mt-20">
                                    <div className="col-md-8">
                                        <h2 className="fs-25 c-dark font-italic fw-600 mobile-fs-20">
                                            Obtenha confiabilidade na era da Internet
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="c-gray font-italic fs-15 text-left wt-435 mt-10">
                                            A internet de fibra óptica foi construída para ser muito mais confiável do
                                            que a internet de linha de cabo tradicional. Pense: (bem) menos interrupções
                                            e mais Internet em comparação com a tecnologia mais antiga de cabo.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row ml-10">
                            <div className="col-md-12">
                                <div className="row mt-20">
                                    <div className="col-md-12">
                                        <h2 className="fs-25 c-dark font-italic fw-600 mobile-fs-20">
                                            Mantenha todo mundo <br/> conectado
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="c-gray font-italic fs-15 text-left wt-435 mt-10">
                                            Uma casa cheia de gente não vai gerar lentidão para
                                            ninguém. (Nem mesmo aquele seu vizinho.) Use todos os
                                            dispositivos em sua casa ao mesmo tempo, sem lentidão,
                                            interrupções ou necessidade de expulsar alguém da
                                            Internet.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="row justify-content-around mt-80 ml-10 mobile-mt-20"
                    id="item-2"
                >
                    <div className="col-md-6">
                        {/* Row - Title*/}
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="fs-25 c-dark font-italic text-right mobile-fs-20 mobile-text-left wt-440">
                                    Experimente o Wi-fi em toda <br/> a sua casa.
                                </h2>
                            </div>
                        </div>
                        {/* Row - Description */}
                        <div className="row">
                            <div className="col-md-12">
                                <p className="c-gray font-italic fs-15 text-right wt-440 mt-10 mobile-text-left">
                                    O seu Wi-Fi não deve ser ignorado! Afinal, ele é essencial para seus tablets,
                                    câmeras de segurança e todos os outros dispositivos sem fio. Com o Gigabit Internet
                                    da LOViZ, você terá cobertura em toda a sua casa, fornecendo um sinal constante, não
                                    importa quantos dispositivos você esteja utilizando. <br/> <Link
                                    to="/super-wifi"
                                    className="call-to-action fs-12 fw-600 font-italic"
                                >Saiba mais sobre nosso Super Wi-Fi.</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Row - Image */}
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <video
                                    src={HouseVideo}
                                    alt="House"
                                    autoPlay={true}
                                    loop={true}
                                    type="video/mp4"
                                    className="border-radius-12 car-running"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className="c-gray font-italic fs-18 text-center mt-10 ">
                                    Cobertura Wi-Fi de ponta a ponta em toda sua residência
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
