import React from "react";
import "./styles.css";

import How from "../../../assets/img/Business/how.webp"
import What from "../../../assets/img/Business/what.webp"

function Items() {
    return (
        <section className="container mb-90">
            <div className="row justify-content-center">
                <div className="col-lg-9">
                    <div className="row align-items-center pt-5">
                        <div className="col-md-6 col-lg-5 pe-md-5 px-5 px-md-0 mb-4 mb-md-0 como-fazemos-img">
                            <img src={How} alt="Como fazemos"/>
                        </div>
                        <div className="offset-lg-1 col-md-6 col-lg-6 reason-box p-4 border-radius-6">
                            <h2 className="c-dark fs-4 fw-600 mb-4 pb-1 mt-3">POR QUE FAZEMOS</h2>
                            <p className="c-dark fw-600 mb-3 fs-14">Acreditamos na força da comunicação, das tecnologias
                                e da inclusão digital como importantes ferramentas de transformação social, crescimento
                                e evolução. Queremos possibilitar aos nossos clientes o melhor cenário e ambientes de
                                trabalho, aprendizado, entretenimento e comunicação. Somos guiados por alegria, energia
                                e paixão pela conexão humana e pela certeza de um mundo cada vez melhor.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-9 reason-box p-4 border-radius-6">
                    <h2 className="c-dark fs-4 fw-600 mb-4 pb-1 mt-3">COMO FAZEMOS</h2>
                    <p className="c-dark fw-600 fs-14 mb-3">Através de avançada rede de fibra óptica, constantes
                        atualizações e monitoramento, conseguimos conectar e levar internet de alta velocidade e conexão
                        telefônica digital a todos os nossos clientes.</p>
                </div>
            </div>

            <div className="row justify-content-center mt-5">
                <div className="col-lg-9">
                    <div className="row align-items-center pt-5 pt-md-3 flex-column-reverse flex-md-row">
                        <div className="col-md-6 col-lg-6 reason-box p-4 border-radius-6">
                            <h2 className="c-dark fs-4 fw-600 mb-4 pb-1 mt-3">O QUE FAZEMOS</h2>
                            <p className="c-dark fw-600 mb-3 fs-14">Oferecemos acesso à internet de alta velocidade,
                                telefonia digital e streaming a pessoas, empresas, condomínios e governo de forma a
                                propiciá-los acesso digital, conexão e entretenimento.</p>
                        </div>
                        <div className="offset-lg-1 col-md-6 col-lg-5 px-5 px-md-0 ps-md-3 mb-4 mb-md-0 como-fazemos-img">
                            <img src={What} alt="Como fazemos"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Items;