import React from "react";
import "./style.css";
import AnnouncementItems from "../../../Components/AnnouncementItems";
import img1 from "../../../assets/img/GigabitInternet/01-SEM-LENTIDAO.jpg";
import img2 from "../../../assets/img/GigabitInternet/02-SEM-TAXAS-OCULTAS.jpg";
import img3 from "../../../assets/img/GigabitInternet/03-SEM-CONTRATOS-FIDELIDADE.jpg";

export default function Announcement() {
  const items = [
    {
      text: "Sem lentidão",
      content: "Sem limite de velocidade",
      image: img1,
    },

    {
      text: "Sem taxas ocultas",
      content: "Sem surpresas no fim do mês",
      image: img2,
    },
  ];

  return (
    <section id="announcement">
      <div className="container">
        <div className="row">
          {items.map(({ text, content, image }, index) => {
            return (
              <AnnouncementItems
                title={text}
                content={content}
                image={image}
                index={index}
              />
            );
          })}
        </div>
      </div>
      <div className="col-md-12 strip mt-40">
        <h1 className="c-white fs-25 font-italic text-center fw-600">
          Internet muito boa e muito rápida.
        </h1>
      </div>
    </section>
  );
}
