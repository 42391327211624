import React, {useState} from "react";
import Announcement from "../../Parts/TrabalheConosco/Announcement";
import Introduction from "../../Parts/TrabalheConosco/Introduction";
import Banner from "../../Parts/TrabalheConosco/Banner";


export default function TrabalheConosco() {

    return (
        <>
            <Banner/>
            <Introduction/>
            <Announcement/>
        </>
    );
}
