import React from "react";
import "./style.css";
import Terms from "../../Parts/Privacidade/index.js";

function Privacidade() {

    return (
        <div id="privacy-page">
            <Terms/>
        </div>
    );
}

export default Privacidade;