import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import img1 from "../../../assets/img/GigabitInternet/04-IMAGEM-LATERAL.jpg";
import img2 from "../../../assets/img/GigabitInternet/05-IMAGEM-ESQUERDA.jpg";
import img3 from "../../../assets/img/GigabitInternet/06-CLASSROOM.jpg";
import img4 from "../../../assets/img/GigabitInternet/07-WIN-LESS-LEG.jpg";
import ContactUs from "../../../Components/ContactUs";
import SecondSectionItems from "../../../Components/SecondSectionItems";

export default function SecondSection() {
  const items = [
    {
      title: `Resolva tudo. Bem rápido.`,
      content: `Seu escritório pode ser seu sofá, mas isso não significa que você está trabalhando menos. Felizmente, você não precisa se preocupar em ser a pessoa que está sempre "interrompendo" a reunião. Oferecemos cobertura para todas as suas necessidades de trabalho em casa`,
      image: img1,
      isInverted: false,
      containLink: false,
    },

    {
      title: `Assista o que você ama - sem
      decodificador.`,
      content: `Quando você assiste TV pela internet, pode assistir
      a quase tudo. Isso inclui TV ao vivo. Fazer a troca é
      fácil. Vamos te mostrar como.`,
      image: img2,
      isInverted: true,
      // containLink: true,
      // linkTo: "loviz-video",
    },

    {
      title: `Transforme sua sala em uma
      sala de aula.`,
      content:`Talvez alguém em casa esteja aprendendo a ler. Talvez outra pessoa esteja se graduando. Ou talvez você esteja aprendendo design gráfico. A Internet Gigabit permite que você faça todas essas coisas - sem conflitos (de internet), lentidão ou interrupções.`,
      image: img3,
      isInverted: false,
      containLink: false,
    },

    {
      title: `Ganhe. Não apenas rapidez..`,
      content: `Esteja você tomando a torre de um inimigo ou cuidando de sua fazenda virtual, você merece o tipo de internet que não cai. E quando você está conectado diretamente, pode experimentar quase 0% de lentidão e perda de dados. Esse tipo de internet foi feito praticamente para quem joga.`,
      image: img4,
      isInverted: true,
      containLink: false,
    },
  ];

  return (
    <section id="second-section">
      <div className="container container-md mb-32">
        {/* Title */}
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center c-dark fs-45 mobile-fs-35">
              Descubra o que você pode fazer com{" "}
              <span className="c-primary">1000 MEGA</span>
            </h1>
          </div>
        </div>
        {items.map(
          ({ title, content, image, isInverted, containLink, linkTo }) => {
            return (
              <SecondSectionItems
                title={title}
                content={content}
                image={image}
                isInverted={isInverted}
                containLink={containLink}
                linkTo={linkTo}
              />
            );
          }
        )}
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center">
            <div className="button-test d-flex align-items-center justify-content-center flex-column">
              <div className="">
                <h1 className="c-dark fs-20 font-italic c-white text-center">
                  Veja como sua internet <br /> se compara a internet <br />{" "}
                  Gigabit da Loviz
                </h1>
              </div>
              <div className="velocity-test-block mt-30 text-center">
                <a href="https://lovizcomvc.speedtestcustom.com" className="fs-12 fw-600 c-white link-button">
                  Faça um Teste de Velocidade
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
