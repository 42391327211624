import React from 'react';

import Banner from '../../Components/Banner';

import BannerBackground from '../../assets/img/Condominium/banner.webp';
import CustomCarousel from '../../Components/CustomCarousel';
import ContactUs from '../../Components/ContactUs';
import Videos from "../../Parts/Condominium/Videos";

import Item1 from '../../assets/img/Condominium/item-1.mp4';
import Item2 from '../../assets/img/Condominium/item-2.mp4';
import Item3 from '../../assets/img/Condominium/item-3.mp4';
import BannerImage from '../../assets/img/condominio.webp';
import './style.css';

function Condominium() {
    const items = [
        {
            video: Item1,
            title: "Mais velocidade",
            description:
                "Garantia de alta velocidade a um custo que cabe no seu bolso.",
        },
        {
            video: Item2,
            title: "Planos personalizados",
            description:
                "Soluções especiais para condomínios residenciais e empresariais.",
        },
        {
            video: Item3,
            title: "Condomínios inteligentes",
            description:
                "Planos atraentes para todos os moradores e condôminos.",
        }
    ];
    return (
        <div id="to-condominium">
            <Banner background={BannerImage}
                    backgroundOverlay="transparent, rgb(0, 0, 0, .5) 60%"
                    classPrincipalTitleColor="c-white text-right"
                    principalTitle="Chega de invejar a</br>internet do seu vizinho."
                    classFontColor="c-white text-right"
                    backgroundPosition="center"
                    description="Internet gigabit para condomínios residenciais e empresariais."
            />
            {/* <Banner
                principalTitle="Sim, o seu Wi-FI<br/>funciona aqui também."
                description="Garanta um Wi-Fi que funcione em toda a sua casa"
                classPrincipalTitleColor="c-white"
                classFontColor="c-white"
                backgroundOverlay="rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)"
                background={BannerImage}
            /> */}
            {/*<CustomCarousel sectionTitle="Velocidade que <span class='c-primary'>seu condomínio precisa.</span>" items={items} />*/}
            <Videos/>
            <ContactUs subtitle={
                <p>
                    Preencha o formulário abaixo e um de nossos representantes entrará
                    em contato. Se você deseja falar com alguém imediatamente, acesse o
                    <a href="https://api.whatsapp.com/send?phone=0800 0800 999"> WhatsApp 0800 0800 999. </a>
                </p>
            }
                       link={'https://api.whatsapp.com/send?phone=558000800999&text=Planos%20Condominios'}/>
        </div>
    );
}

export default Condominium;