import React, { useEffect, useState } from "react";
import "./style.css";

import CallToAction1 from "../../Parts/Home/CallToAction1";
import WifiHome from "../../Parts/Home/WifiHome";

import CustomCarousel from "../../Components/CustomCarousel";
import Banner from "../../Components/Banner";
import FormCep from "../../Components/FormCep";
import ContactUs from "../../Components/ContactUs";

import BannerImage from "../../assets/img/home/banner.webp";
import vide_connecting_home from "../../assets/img/home/01-Conectando sua casa.mp4";
import video_games from "../../assets/img/home/02-GAMES.mp4";
import video_working_in_home from "../../assets/img/home/04-Trabalhando em casa.mp4";
import video_teaching from "../../assets/img/home/05-Aprendizado.mp4";

function Home() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const carouselItems = [
        {
            video: vide_connecting_home,
            title: "Conectando sua casa",
            description:
                "Suas lâmpadas inteligentes, seu sistema de segurança integrado e sua geladeira Smart são tão inteligentes quanto a sua internet.",
        },
        {
            video: video_games,
            title: "Games",
            description:
                "Jogue melhor e ganhe mais jogando com a internet gigabit.",
        },
        {
            video: video_working_in_home,
            title: "Trabalhando em casa",
            description:
                "Seu escritório pode ser o seu sofá, mas isso não significa trabalhar menos.",
        },
        {
            video: video_teaching,
            title: "Aprendizado",
            description:
                "Ensine e aprenda virtualmente sem interrupções e conflitos de conexão.",
        },
    ];

    const wifiSection = {
        dog: {
            start: { translateX: -50, translateY: -48, rotate: 0 },
            end: { translateX: -130, translateY: -48, rotate: -10 },
        },
        pan: {
            start: { translateX: -50, translateY: -48, rotate: 0 },
            end: { translateX: 30, translateY: -48, rotate: 10 },
        },
    };

    useEffect(() => {
        function wifiSectionEffect() {
            const sectionEl = document.querySelector("#wifi-section");
            if (sectionEl) {
                const dogEl = sectionEl.querySelector(".dog");
                const panEl = sectionEl.querySelector(".pan");

                const progress = checkWifiSection(
                    (window.innerHeight - sectionEl.getBoundingClientRect().top) /
                    window.innerHeight
                );
                dogEl.style.transform = getImageTransform("dog", progress);
                panEl.style.transform = getImageTransform("pan", progress);
            }
        }

        window.addEventListener("scroll", () => wifiSectionEffect());
        window.addEventListener("resize", () => wifiSectionEffect());

        return (
            () => window.removeEventListener("scroll", wifiSectionEffect()),
            window.removeEventListener("resize", wifiSectionEffect())
        );
    }, []);

    function getImageTransform(el, progress) {
        return `translate(${getVal(el, "translateX", progress)}%, ${getVal(
            el,
            "translateY",
            progress
        )}%) rotate(${getVal(el, "rotate", progress)}deg)`;
    }

    function getVal(t, e, progress) {
        let i = wifiSection[t],
            s = 1 - checkWifiSection(2.2 * (progress - 0.5));
        return (i.start[e] - i.end[e]) * s + i.end[e];
    }

    function checkWifiSection(value) {
        return value < 0 ? (value = 0) : value > 1 && (value = 1), value;
    }

    return (
        <>
            <div id="home-page">
                {/*<BannerNew />*/}
                <Banner
                    backgroundOverlay={
                        windowDimensions["width"] < 768
                            ? "rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.7)"
                            : "rgb(255 255 255 / 70%) 30%, transparent"
                    }
                    background={BannerImage}
                    principalTitle="Ame sua internet"
                    backgroundPosition="center"
                    secondaryTitle=""
                    description='
                    <div class="fs-25 m-l-5 mobile-hide-margin">Sim, tem como ser mais rápido.</div>
                    <div class="banner__offer">
                     <div class="banner__content">
                      <div class="offer-left">
                        <div class="first-col">
                            <p class="por">Até</p>
                          </div>
                          <div class="second-col">
                            <p class="price">1000</p>
                            <div class="megas">Megabits</div>
                          </div>
                      </div>
                      <div class="vertical-hr"></div>
                      <div class="offer-price">
                        <div class="price-wrapper">
                          <div class="first-col">
                            <div class="mes">A partir</div>
                            <div class="mes">de R$</div>
                          </div>
                          <div class="second-col">
                            <p class="price">109</p>
                          </div>
                          <div class="third-col">
                            <div class="cents"></div>
                            <div class="mes">/MÊS*</div>
                          </div>
                        </div>
                      </div>
                      <div class="offer-button-content">
                            <a href="/gigabit-internet">Veja os planos</a>
                      </div>
                    </div>
                </div>'
                />
                <FormCep
                    sectionClass="bg-gray p-0"
                    parentColumnSize="12"
                    containsWhatsAppColumn={true}
                />
                <CallToAction1 />
                <CustomCarousel
                    items={carouselItems}
                    sectionTitle='Sua internet. <span class="c-primary">Para tudo.</span>'
                />
                <WifiHome />
                <ContactUs link={'https://api.whatsapp.com/send?phone=558000800999&text=Contato%20Geral'} />
            </div>
        </>
    );
}

export default Home;
