import React from "react";
import "./styles.css";

import Anatel from "../../../assets/img/Business/anatel.webp"
import Check from "../../../assets/img/Business/check.jpg"
import Folders from "../../../assets/img/Business/folders.png"

import Ato from "../../../assets/img/Business/ato.pdf"
import Cnpj from "../../../assets/img/Business/cnpj.pdf"
import Sintegra from "../../../assets/img/Business/sintegra.pdf"

function Icons() {

    return (
    <section id="icons">
        <div className="container">
            <div className="row mt-50 justify-content-center">
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                            <img src={Check} alt="Empresa Legal" width="80px"/>
                        </div>
                    </div>
                    <div className="row mt-10">
                        <div className="col-md-12 text-center">
                            <h3 className="fs-20 mb-0">Empresa Legal.</h3>
                        </div>
                    </div>
                    <div className="row mt-10 justify-content-center">
                        <div className="col-md-8 text-center">
                            <span className="c-gray fs-14">
                              Empresa regularmente inscrita e comprovadamente legal.
                                <a href={Cnpj} target="_blank" rel="noreferrer" className="text-decoration-underline"> Acompanhe.</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mobile-mt-20">
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                            <img
                                src={Folders}
                                alt="Documentos Empresariais"
                                width="80px"
                            />
                        </div>
                    </div>
                    <div className="row mt-10">
                        <div className="col-md-12 text-center">
                            <h3 className="fs-20 mb-0">Documentos Empresariais.</h3>
                        </div>
                    </div>
                    <div className="row mt-10 justify-content-center">
                        <div className="col-md-8 text-center">
                            <span className="c-gray fs-14"><a href={Sintegra} target="_blank" rel="noreferrer" className="text-decoration-underline">Consulte aqui </a>
                                documentos de comprovação de regularidade da LOViZ.</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 mobile-mt-20 justify-content-center">
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                            <img
                                src={Anatel}
                                alt="Termos de Autorização da ANATEL"
                                width="80px"
                            />
                        </div>
                    </div>
                    <div className="row mt-10">
                        <div className="col-md-12 text-center">
                            <h3 className="fs-20 mb-0">Termos de Autorização da ANATEL</h3>
                        </div>
                    </div>
                    <div className="row mt-10 justify-content-center">
                        <div className="col-md-8 text-center">
                <span className="c-gray fs-14">
                  <a href={Ato} target="_blank" rel="noreferrer" className="text-decoration-underline">Consulte aqui </a>os termos de Autorização da ANATEL
                </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default Icons;