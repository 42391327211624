import React from "react";
import "./style.css";
import img from "../../../assets/img/IOT/f-gamer.webp";

export default function Introudction() {
  return (
    <section id="iot-introdution">
      <div className="container">
        <div className="row align-items-end justify-content-center">
          <div className="col-md-6">
            <div className="background-image pl-50 ptb-20 mobile-padding-left">
              {/* Background Image */}
              <div className="row mt-40">
                <div className="col-md-12">
                  {/* Title - Backgroud Image */}
                  <h1 className="fs-40 c-white text-left mobile-fs-25 mobile-text-center">
                    Seus equipamentos <br />
                    todos conectados!
                  </h1>
                </div>
              </div>
              {/* Subtitle - Backgroud Image */}
              <div className="row mt-20">
                <div className="col-md-10 col-lg-7">
                  <p className="fs-20 c-white mobile-text-center">
                    Com a internet 4.5G+ sua vida fica mais dinâmica e
                    tecnológica.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <img
                  src={img}
                  alt=""
                  className="img-iot mobile-position-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
