import React from "react";
import "./style.css";
import img from "../../../assets/img/IOT/120071-OPU2QP-198-01.webp";

export default function Information() {
  return (
    <section id="information-iot">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="fs-40 c-dark text-center">
              Entenda o <span className="c-primary">IoT.</span>
            </h1>
          </div>
        </div>
        <div className="row mt-50">
          <div className="col-md-5">
            <div className="row">
              <div className="col-md-12">
                <img src={img} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="row mt-50 mobile-mt-20">
              <div className="col-md-12">
                <h2 className="c-primary fs-25 mobile-text-center">
                  Internet das coisas
                </h2>
              </div>
            </div>
            <div className="row mt-10">
              <div className="col-md-9 col-lg-8">
                <p className="fs-15 font-italic fw-600 c-gray iot-text">
                  O conceito de IoT (internet of things, em tradução: internet
                  das coisas) é mais simples do que aparenta: a cada dia mais e
                  mais aparelhos eletrônicos estão conectados na internet. Essa
                  conexão cria uma grande rede multifuncional de equipamentos
                  inteligentes que podem ser controlados remotamente e através
                  de uma única fonte - como o seu celular, por exemplo. Mas se
                  tudo está conectado, você precisa de uma internet que dê conta
                  de tudo isso - o 4.5G+ é a sua solução.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
