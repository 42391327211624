import React from "react";
import "./style.css";
import img1 from "../../../assets/img/Trabalhe Conosco/young-man-engineer-making-program-analyses.webp";
import img2 from "../../../assets/img/Trabalhe Conosco/smiling-colleagues-making-notes-plans.webp";
import img3 from "../../../assets/img/Trabalhe Conosco/smiling-friendly-man-working-call-center-office-with-team.webp";

function AnnouncementItems({ image, alt, title, content, index }) {
  return (
    <div className="col-md-4 mobile-mt-20" key={index}>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <img src={image} alt={alt} className="border-radius-12" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <h2 className="text-left fs-20 ptb-20 fw-600 font-italic mt-10 c-dark">
            {title}
          </h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-11">
          <p className="fs-14 font-italic fw-600 c-gray">{content}</p>
        </div>
      </div>
    </div>
  );
}

export default function Announcement() {
  const data = [
    {
      image: img1,
      title: "Engenharia de Redes",
      content: `Os membros da equipe de engenharia de rede
      da LOViZ mantêm operações de rede seguras e
      eficientes. Eles supervisionam o desempenho de
      nossa rede e nossa manutenção planejada, e
      respondem a interrupções e problemas em
      tempo real.`,
    },

    {
      image: img2,
      title: "Marketing e Administrativo",
      content: `Nossas equipes de marketing e administrativo
      estão focadas no gerenciamento estratégico da
      empresa e da marca LOViZ. São criativos,
      pensam fora da caixa e fazem o impossível para
      gerar valor e benefício aos clientes.`,
    },

    {
      image: img3,
      title: "Vendas e Suporte",
      content: `O time de operações de vendas e suporte ao cliente está 24h ligado nas necessidades individuais de cada unidade. Buscam não apenas vender, mas solucionar problemas, engajar e cultivar relações prósperas com os clientes.`,
    },
  ];
  return (
    <section id="announcement-section">
      <div className="container">
        <div className="row mb-32">
          <div className="col-md-12">
            <h1 className="fs-35 c-dark text-center mobile-fs-25">
              Uma olhada em algumas de{" "}
              <span className="c-primary">nossas funções.</span>
            </h1>
          </div>
        </div>
        <div className="row mt-30">
          {data.map(({ title, content, image }, index) => (
            <AnnouncementItems
              title={title}
              content={content}
              image={image}
              key={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
