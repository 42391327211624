import React from "react";
import "./style.css";

import SuperWifi from "../../../assets/img/4.5G/super-wifi.webp";
import TopViewWiFi from "../../../assets/img/top-view-wi-fi-router.webp";
import WifiToAll from "../../../assets/img/wifi-to-all.webp";


function SuperWifiSection() {
  return (
    <section id="superwifi-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2 className="section-title fs-35">
              Pronto para o que vem por
              <br />
              aí com o <span className="c-primary ws-pre">Super Wi-Fi?</span>
            </h2>
          </div>
        </div>
       
        <div className="row mt-50 justify-content-center">
          <div className="col-md-3 d-flex align-items-center justify-content-center">
            <img src={SuperWifi} alt="Wi-Fi 6" width="180px" />
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <h3 className="fs-20 fw-600">
                  Todos os dispositivos wireless da sua família. Todos mesmo.
                </h3>
              </div>
            </div>
            <div className="row mt-20">
              <div className="col-md-12">
                <p className="c-gray fs-15">
                Sua casa usa muitos dispositivos sem fio. Você precisa de um poderoso Wi-Fi que possa lidar com todos eles. É por isso que, com o Super Wi-Fi da LOViZ, por apenas 12X de R$49,88* você adiciona um novo terminal de acesso, ampliando sua conexão.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="router-section" className="row mt-50 justify-content-center">
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-md-12">
                <h3 className="fs-35 fw-600">
                  Use o roteador que funciona{" "}
                  <span className="c-primary">para você.</span>
                </h3>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12">
                <p className="c-gray fs-15 mb-0">
                Já tem um sistema que adora? Use-o. Seu serviço inclui Wi-Fi sem custo extra, mas entendemos que você pode preferir outro serviço. É por isso que, ao escolher nosso plano de Internet Gigabit de 1.000 MEGA, você pode usar seu próprio roteador.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 d-flex mobile-mt-40">
            <img src={TopViewWiFi} alt="Wi-Fi 6" />
          </div>
        </div>
        <div id="wifi-to-all" className="row mt-80 justify-content-center">
          <div className="col-md-4 d-flex justify-content-center mobile-mt-40">
            <img src={WifiToAll} alt="Wi-Fi para tudo que você precisa." />
          </div>
          <div className="col-md-4 d-flex flex-column justify-content-center">
            <div className="row">
              <div className="col-md-12">
                <h3 className="fs-35 fw-600">
                  Wi-Fi para tudo
                  <br />
                  que você precisa.
                </h3>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12">
                <p className="c-gray fs-15 mb-0">
                  Sempre atualizando sua casa e equipamentos de jogos? Com o
                  Super Wi-Fi, você estará pronto para todos os dispositivos
                  mais recentes. Você também terá velocidades de Wi-Fi 30% mais
                  rápidas em diversos dispositivos, sem zonas mortas ou
                  interrupções.
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default SuperWifiSection;
