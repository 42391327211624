import React from "react";
import ButtonCall from "../../../Components/ButtonCall";
import "./style.css";

export default function CallToAction1() {
  return (
    <section id="call-to-action-1-section" className="bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-12">
                <span>Seja diferente</span>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="c-primary fs-45 mobile-fs-25">
                    Sua internet - internet ultra rápida para toda sua família.
                    </h2>
                  </div>
                </div>
                <div className="row mt-30">
                  <div className="col-md-12">
                    <ButtonCall title="Saiba Mais" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
